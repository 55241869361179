.profile-form {
    width: 90%;
    padding: 1em;
    /* border: 1px solid #ccc;
    border-radius: 5px;
    background: #00051c; */
}

.profile-form h2 {
    text-align: center;
    margin-bottom: 1em;
    color: #fff;
}

.form-group label {
    display: block;
    margin-bottom: 0.5em;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 0.5em;
    font-size: 14px;
    box-sizing: border-box;

    background: #2e2e2e;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #ffffff;
    margin-bottom: 10px;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.cancel-btn,
.save-btn {
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn {
    background: #fff;
    color: #000;
    border-radius: 10px;
    font-weight: 600;
}

.save-btn {
    background: #4CAF50;
    color: #fff;
}

.profile-info {
    display: flex;
    /* align-items: center; */
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.image-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    text-align: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;


    color: transparent;
    font-size: 14px;
    /* Kích thước chữ */
    cursor: pointer;
}

.clip-loader {
    position: absolute;
    /* Định vị tuyệt đối so với .image-wrapper */
    top: 35%;
    /* Canh giữa theo chiều dọc */
    left: 35%;
    /* Canh giữa theo chiều ngang */
    transform: translate(-50%, -50%);
    /* Canh giữa chính xác */
    z-index: 1;
    /* Đảm bảo nó nằm trên */
}

.input-container {
    position: relative;
    display: flex;
}

.input-container input {
    padding-right: 40px;
}

.copy-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 36px;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    padding: 0 10px;
    font-size: 14px;
}

.copy-button:hover {
    color: #3399fe;
}

.change-password-modal {
    position: absolute;
    border: none;
    background-color: #343A40;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    max-height: 400px;
    max-width: 600px;
    margin: auto;
    padding: 2%;
    color: white;
    border-radius: 10px;
}

.form-change-password {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 35px;
    align-items: center;
    text-align: left;
}

.form-change-password input {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.form-change-password label {
    color: white;
    align-self: flex-start;
    padding-bottom: 3px;
}

.change-password-button {
    border-radius: 5px;
    border: 0;
    margin-top: 2px;
}

.change-password-submit-button {
    width: 100%;
    background-image: linear-gradient(to right,
            #fb6fbb 0%,
            #ff9085 51%,
            #fa1191 100%);
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #151517;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    margin-top: 20px;

    &:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
    }
}

.change-password-modal-title {
    text-align: center;
    font-size: 20px;
}

@media (max-width: 850px) {
    .avatar {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 400px) {
    .avatar {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 850px) {
    .change-password-modal {
        max-width: 400px;
    }
}

@media (max-width: 400px) {
    .change-password-modal {
        max-width: 350px;
    }
}

@media (max-height: 400px) {
    .change-password-modal-title {
        font-size: 15px;
    }

    .form-change-password label {
        font-size: 10px;
    }

    .form-change-password {
        margin-top: 10px;
    }

    .form-change-password input {
        margin-bottom: 1px;
        height: 10px;
    }

    .form-change-password button {
        margin-top: 7px;
    }

    .change-password-submit-button {
        height: 25px;
        padding: 5px 25px;
        font-size: 10px;
    }

    .change-password-modal {
        max-width: 400px;
        max-height: 200px;
    }
}
