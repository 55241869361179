.abc {
    justify-content: center;
    align-items: center;
}

.tp {
    height: 20px;
    display: inline-flex;
    width: 100%;
    // margin-bottom: 10px;
    margin-top: -30px;

    input[type=radio] {
        height: 100%;
        width: 20px;
    }
}

.tpsl {
    display: flex;
    text-align: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: white;
}

.advanced {
    display: inline-block;
    align-items: center;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-align: right;
    color: white;
}
