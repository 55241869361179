.container-term-service {
    color: #EAECEF;
    font-size: 16px;
    margin-top: 50px;
}

.content-term-service {

    a {
        color: pink;
        text-decoration: underline;
    }

    /* br {
        padding: 12px 0px;
    } */

}
.hihi {
    padding: 12px 0px;
}

.content-term-service > *{
    padding: 12px 0px;
}

.center-text {
    text-align: center;
}

.bold-text {
    font-weight: 700;
}

