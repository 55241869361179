.centered-view-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-view {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: -10px;
}

.centered-view-content {
    background: #212529;
    padding: 10px 20px 20px 20px;
    border-radius: 5px;

    h3 {
        margin-bottom: 10px;
        margin-top: 5px;
    }

    h5 {
        /* margin-bottom: 10px; */
        font-weight: 400;
    }
}

.input-image > * {
    margin: 15px 0px;
}
.between-space {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.delete-button {
    /* background-color: black; */
}
