@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";
@import "../../styles/_global.scss";

.scrolled {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
  backdrop-filter: blur(24px);
  transition: shadow 0.3s ease-in-out;
}

.navbar {
  padding: 1rem;
  z-index: 9999;
  padding: 0;
  height: 70px;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: shadow 0.3s ease-in-out;

  .navbar__container {
    display: flex;
    justify-content: space-between;
    max-width: $max-width;
    width: 1200px;

    @include respond-to(tablet-p) {
      width: 100%;
      padding: 0;
      max-width: none;
    }
  }

  .navbar__left,
  .navbar__right {
    flex: 0.8;
  }

  // RIGHT SIDE
  .navbar__right {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include respond-to(tablet-p) {
      justify-content: end;
    }

    button {
      @include respond-to(tablet-p) {
        display: none;
      }
    }

    // MOBILE MENU
    .navbar__mobile__menu {
      visibility: hidden;
      position: absolute;
      cursor: pointer;
      color: $light-color;
      @include flexCenter();
      font-size: 2rem;
      right: 0;

      @include respond-to(tablet-p) {
        display: flex;
        visibility: visible;
        position: relative;
      }
    }

    .navbar__mobile__menu__items {
      height: 60&;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 62px;
      display: flex;
      display: none;
      flex-direction: column;
      padding: 0.5rem;
      border-radius: 0.5rem;
      backdrop-filter: blur(10px);
      color: white;
      border: 2px solid #9c7bf2;
      gap: 1rem;

      @include respond-to(tablet-p) {
        display: flex;
      }

      .navbar__right__icons {
        @include respond-to(tablet-p) {
          display: flex;
          opacity: 0.6;
        }
      }

      button {
        @include respond-to(tablet-p) {
          display: flex;
          white-space: nowrap;
        }
      }

      .navbar__mobile__menu__item {
        position: relative;
        cursor: pointer;
      }
    }

    .navbar__mobile__menu__item__dropdown {
      display: flex;
      padding: 0.5rem;
      border-radius: 0.5rem;
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      border-radius: 0.5rem;
      gap: 1rem;
      background-color: rgba(255, 255, 255, 0.126);
      text-decoration: none;
      margin-top: 0.8rem;

      a {
        color: $light-color;
        font-size: $extra-small-font-size;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .navbar__right__icons {
      @include respond-to(tablet-p) {
        display: none;
      }

      gap: 24px;
      display: flex;
      align-items: center;

      a {
        @include flexCenter();
        transition: all 0.3s ease-in-out;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  // LEFT SIDE
  .navbar__left {
    flex: 2 !important;
    display: flex;
    align-items: center;

    gap: 3rem;

    .navbar__left__logo {
      display: flex;
      align-items: center;
    }

    .navbar__left__items {
      @include respond-to(tablet-p) {
        display: none;
      }

      display: flex;
      gap: 1rem;

      .navbar__left__item {
        position: relative;
        color: $light-color;
        font-size: $small-font-size;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        display: flex;
        gap: 2px;
        transition: all 0.1s ease-in-out;

        .navbar__left__item__dropdown {
          top: 30px;
          left: 0px;
          position: absolute !important;
          transition: all 0.3s ease-in-out;
          padding: 1rem;
          color: $light-color;
          border-radius: 5px;
          background-color: $card-bg-color;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 230px;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          transition: all 0.1s ease-in-out;
          --borderWidth: 3px;

          .navbar__left__item__dropdown__item__link {
            .navbar__left__item__dropdown__item {
              transition: all 0.3s ease-in-out;
              color: $card-desc-color !important;
              white-space: nowrap;

              &:hover {
                opacity: 0.8;
                color: $light-color !important;
              }
            }
          }
        }

        .navbar__left__item__dropdown:after {
          content: "";

          position: absolute;
          top: calc(-1 * var(--borderWidth));
          left: calc(-1 * var(--borderWidth));
          height: calc(100% + var(--borderWidth) * 2);
          width: calc(100% + var(--borderWidth) * 2);
          background: linear-gradient(60deg,
              #f79533,
              #f37055,
              #ef4e7b,
              #a166ab,
              #5073b8,
              #1098ad,
              #07b39b,
              #6fba82);
          border-radius: 6px;
          z-index: -1;
          animation: animatedgradient 3s ease alternate infinite;
          background-size: 300% 300%;
        }

        // &:hover {
        //   font-weight: $bold-weight;
        //   border-image-source: linear-gradient(
        //     to left,
        //     #ff9085 30%,
        //     #fb6fbb 99%
        //   );
        //   background-image: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        //   -webkit-background-clip: text;
        //   background-clip: text;
        //   -webkit-text-fill-color: transparent;
        // }
      }
    }
  }
}

.right_navbar_login {
  display: flex;
  justify-content: space-between;
  /* Các phần tử con sẽ bắt đầu từ bên trái */
  align-items: center;

  .name {
    color: white;
    flex-grow: 1;
    // white-space: nowrap; /* Ngăn xuống dòng */
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    max-width: 280px;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
    }
  }
}


.info_logined {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;

  .profile_button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-width: 0;
    background-color: transparent;

  }

  .dropdown_profile {
    background-color: #161617;
    border: 3px solid #fc76ae;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    position: absolute;
    right: 4px;
    top: 50px;
    width: 200px;
    z-index: 10;

    .dropdown_item {
      h3 {
        color: #fff;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }

      span {
        color: #fff;
        font-size: smaller;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
    }
  }

  .notification {
    margin-right: 5px;
  }
}

.dropdown_item>* {
  margin-bottom: 10px;
}


@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 940px) {
  .notification {
    display: none;
  }
}

@media (max-width: 890px) {
  .navbar__left {
    flex: 1 !important;
  }
}

@media (max-width: 700px) {
  .navbar__left {
    flex: 0.7 !important;
  }
}

@media (max-width: 400px) {
  .right_navbar_login {
    .name {
      display: none;
    }
  }
}
.logout {
  background-color: transparent;
  display: flex;
  border-width: 0;

  span {
    font-size: 17px !important;
    color: white;
  }

  img {
    height: 17px;
    width: 17px;
    margin-left: 5px;
  }

  &:hover {
    background-image: $gradient-color;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
}
