  /* sweet alert */

  .swal-overlay--show-modal .swal-modal {
    background-color: #0e0e0e;
  }

  .swal-icon--success:before {
    background-color: #0e0e0e;
  }
  .swal-icon--success:after {
    background-color: #0e0e0e;
  }
  .swal-icon--success__hide-corners{
    background-color: #0e0e0e;
  }
  .swal-title {
    color: #fff;
  }
  .swal-text {
    color: #fff;
  }