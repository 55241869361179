.container {
    flex-direction: column;

    .availablePrice {
        display: flex;
        justify-content: space-between;

        p {
            color: white;
            font-size: 14px;
        }
    }
}

.input_focus {
    border: 0.5px solid white;
}

.inputAmount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 52px;
    width: 100%;
    padding: 7px 0px 8px 9px;
    border-radius: 5px;
    background-color: #222222;
    margin-bottom: 30px;

    label {
        color: #e9e9e9;
        font-weight: 100;
        width: 100%;
        padding-bottom: 2px;
        font-size: 12px;
    }

    input {
        background-color: #222222;
        border: 0px;
        // height: 16px;
        width: 100%;
        outline: none;
        color: white;
        font-size: 16px;
        font-weight: 400;
    }
}

.inputAmount:hover {
    border: 0.5px solid white;
}