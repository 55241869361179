@import url(https://fonts.cdnfonts.com/css/axiforma);
@import url(https://fonts.googleapis.com/css?family=Lobster);
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

.Home_home__ODbm1 {
    display: flex;
    flex-direction: column;
    gap: 5rem
}

.Hero_hero__pWuPR {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative
}

@media(max-width:768px) {
    .Hero_hero__pWuPR {
        align-items: center;
        display: flex;
        justify-content: center
    }
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 5rem
}

@media(max-width:768px) {
    .Hero_hero__pWuPR .Hero_hero__left__a6prs {
        width: 84% !important
    }
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs:after {
    position: absolute;
    top: -20px;
    z-index: -1
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs:before {
    left: 25%;
    position: absolute;
    top: 100px;
    z-index: -1
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__text__Shd1K h1 {
    color: #fff;
    font-size: 56px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(max-width:768px) {
    .Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__text__Shd1K h1 {
        font-size: 44px !important
    }
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__text__Shd1K h1:first-child {
    font-weight: 700
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__text__Shd1K h1:nth-child(2),
.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__text__Shd1K h1:nth-child(3) {
    font-weight: 300
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__btns__IQ\+D5 {
    display: flex;
    gap: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__btns__IQ\+D5 .Hero_hero__left__btns_items__W-RPn {
    background-color: transparent !important;
    border: 2px solid #fff;
    color: #fff;
    transition: all .3s ease-in-out
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__btns__IQ\+D5 .Hero_hero__left__btns_items__W-RPn:hover {
    opacity: .7
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__bottomText__oYrnR {
    color: #fff;
    font-size: 20px
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__backedBy__KDTsA {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__backedBy__KDTsA p {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    font-weight: 700;
    outline: none
}

@media(max-width:768px) {
    .Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__backedBy__KDTsA img {
        width: 200px !important
    }
}

.Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__backedBy__KDTsA .Hero_hero__left__backedBy__logos__vr7wm {
    display: flex;
    gap: 2rem;
    width: 250px
}

@media(max-width:768px) {
    .Hero_hero__pWuPR .Hero_hero__left__a6prs .Hero_hero__left__backedBy__KDTsA .Hero_hero__left__backedBy__logos__vr7wm {
        flex-direction: column
    }
}

.Hero_headerVideo__b1M6\+ {
    position: fixed
}

@media(max-width:768px) {
    .Hero_headerVideo__b1M6\+ {
        height: 150vh !important
    }
}

.Button_button__f49Fy {
    background-size: 200% auto;
    border: none;
    border-radius: 10px;
    color: #151517;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: .5s
}

.Button_button__f49Fy:hover {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: .8
}

.Button_btn__grad__hwx9m {
    background-image: linear-gradient(90deg, #fb6fbb 0, #ff9085 51%, #fa1191);
    background-size: 200% auto;
    border-radius: 10px;
    color: #151517;
    display: block;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: .5s
}

.Button_btn__grad__hwx9m:hover {
    background-position: 100%;
    color: #fff;
    text-decoration: none
}

.Card_cards__7M3Qm {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    margin-top: 3rem;
    min-height: 800px
}

@media(max-width:768px) {
    .Card_cards__7M3Qm {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: center
    }
}

.Card_cards__7M3Qm .Card_cards__card__left__G\+jMO {
    flex: 1 1
}

@media(max-width:768px) {
    .Card_cards__7M3Qm .Card_cards__card__left__G\+jMO {
        margin-bottom: 2.5rem !important;
        width: 82%
    }
}

.Card_cards__7M3Qm .Card_cards__card__left__G\+jMO .Card_cards__card__item__\+Vq2C {
    background-image: url(./../media/HyperStore.a61326c4aa2af9d0efda.png)
}

.Card_cards__7M3Qm .Card_cards__card__left__G\+jMO .Card_cards__card__item__content__link__XRJpa {
    -webkit-text-fill-color: transparent;
    -webkit-animation: Card_animate__zp6\+F 10s linear infinite;
    animation: Card_animate__zp6\+F 10s linear infinite;
    -webkit-background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    cursor: pointer;
    font-weight: 800;
    outline: none;
    text-transform: uppercase
}

.Card_cards__7M3Qm .Card_cards__card__left__G\+jMO .Card_cards__card__item__\+Vq2C {
    height: 100%
}

.Card_cards__7M3Qm .Card_cards__card__left__G\+jMO .Card_cards__card__item__\+Vq2C .Card_cards__card__item__img__ZkmUd {
    flex: 2 1
}

.Card_cards__7M3Qm .Card_cards__card__left__G\+jMO .Card_cards__card__item__\+Vq2C .Card_cards__card__item__content__VEtd5 {
    color: #fff;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 34px;
    font-weight: 700
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: space-between
}

@media(max-width:768px) {
    .Card_cards__7M3Qm .Card_cards__card__right__lumL3 {
        width: 82%
    }
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 a {
    color: #898990;
    font-size: 16px !important;
    text-decoration: none
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__content__VEtd5 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__content__text__rPnMY {
    font-size: 27px !important
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__\+Vq2C {
    flex-basis: 100%;
    min-height: 380px
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__top__6rlal {
    width: 100%
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__top__6rlal .Card_cards__card__item__\+Vq2C {
    background-image: url(./../media/HyperSDK.c49607f6227a857493d1.png) !important;
    background-size: cover !important;
    min-height: 380px
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__top__6rlal .Card_cards__card__item__\+Vq2C .Card_cards__card__item__img__ZkmUd {
    flex: 2 1
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__top__6rlal .Card_cards__card__item__\+Vq2C .Card_cards__card__item__content__VEtd5 {
    flex: .3 1
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo {
    display: flex;
    gap: 2.5rem;
    position: relative
}

@media(max-width:768px) {
    .Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo {
        flex-direction: column;
        width: 100%
    }
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo .Card_cards__card__item__first__g\+OE5,
.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo .Card_cards__card__item__second__t2Sqr {
    background-image: url(./../media/HyperNFT.10e8368406e8a9e15938.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important
}

@media(max-width:768px) {
    .Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo .Card_cards__card__item__first__g\+OE5,
    .Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo .Card_cards__card__item__second__t2Sqr {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important
    }
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo .Card_cards__card__item__second__t2Sqr {
    background-image: url(./../media/HyperBoost.7a23de3882c7712855e9.png) !important
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo:after {
    content: url(./../media/CircleSecond.b3f0a2bb65d6bb4934a5.svg);
    left: 300px;
    position: absolute;
    top: -130px;
    z-index: -1
}

.Card_cards__7M3Qm .Card_cards__card__right__lumL3 .Card_cards__card__item__bottom__obMvo .Card_cards__card__item__\+Vq2C {
    box-shadow: 0 1px 2px rgba(0, 0, 0, .07), 0 2px 4px rgba(0, 0, 0, .07), 0 4px 8px rgba(0, 0, 0, .07), 0 8px 16px rgba(0, 0, 0, .07), 0 16px 32px rgba(0, 0, 0, .07), 0 32px 64px rgba(0, 0, 0, .07);
    height: 100%;
    max-width: 348px;
    min-width: 348px
}

.Card_cards__7M3Qm .Card_cards__card__item__\+Vq2C {
    background-color: #232325;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem;
    transition: all .3s ease-in-out
}

.Card_cards__7M3Qm .Card_cards__card__item__\+Vq2C .Card_cards__card__item__img__ZkmUd {
    flex: 1 1
}

.Card_cards__7M3Qm .Card_cards__card__item__\+Vq2C .Card_cards__card__item__content__text__rPnMY {
    color: #fff;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.13;
    text-align: left
}

.Card_cards__7M3Qm .Card_cards__card__item__\+Vq2C .Card_cards__card__item__title__h8aRb {
    color: #898990;
    font-size: 14px
}

.Card_cards__card__item__hover__kW4XA {
    --borderWidth: 3px;
    position: relative;
    transition: all .1s ease-in-out
}

.Card_cards__card__item__hover__kW4XA:after {
    -webkit-animation: Card_animatedgradient__ws-K9 3s ease infinite alternate;
    animation: Card_animatedgradient__ws-K9 3s ease infinite alternate;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 300% 300%;
    border-radius: 21px;
    content: "";
    height: calc(100% + var(--borderWidth)*2);
    left: calc(var(--borderWidth)*-1);
    position: absolute;
    top: calc(var(--borderWidth)*-1);
    width: calc(100% + var(--borderWidth)*2);
    z-index: -1
}

@-webkit-keyframes Card_animatedgradient__ws-K9 {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    to {
        background-position: 0 50%
    }
}

@keyframes Card_animatedgradient__ws-K9 {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    to {
        background-position: 0 50%
    }
}

.Marquee_Marquee__img__S8ZsI {
    cursor: pointer;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin: 0 2rem;
    transition: all .3s ease-in-out;
    width: 200px
}

.Marquee_Marquee__img__S8ZsI:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.Ecosystem_ecosystem__Nm\+Hc {
    display: flex;
    flex-direction: column;
    gap: 56px
}

@media(max-width:768px) {
    .Ecosystem_ecosystem__Nm\+Hc {
        flex-direction: column;
        padding-left: 1rem;
        width: 82%
    }
}

.Ecosystem_ecosystem__Nm\+Hc:after {
    content: url(./../media/World.2abd915ad229aa947150.svg);
    left: 42%;
    margin-top: -4rem;
    position: absolute;
    z-index: -1
}

@media(max-width:768px) {
    .Ecosystem_ecosystem__Nm\+Hc:after {
        display: none
    }
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+ {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 3rem 0;
    width: 100%
}

@media(max-width:768px) {
    .Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+ {
        flex-direction: column;
        gap: 1rem;
        margin: 1rem 0
    }
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_container__TrRPH {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 90%
}

@media(max-width:768px) {
    .Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_container__TrRPH {
        flex-direction: column;
        gap: 1rem;
        margin-left: 3rem;
        text-align: center;
        width: 100%
    }
    .Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_container__TrRPH input {
        width: 100%
    }
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_container__TrRPH span {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_input__M78FB {
    background: #232325;
    border: 1px solid #fb6fbb;
    border-radius: 10px;
    color: #fff;
    font-size: 1.2rem;
    height: 50px;
    padding: 0 1rem;
    width: 100%;
    width: 50%
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_input__M78FB:focus {
    outline: none
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_input__M78FB::-webkit-input-placeholder {
    color: #fff;
    opacity: .5
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_newslatter_wrapper__NtLS\+.Ecosystem_newslatter_input__M78FB::placeholder {
    color: #fff;
    opacity: .5
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM {
    display: flex;
    justify-content: space-between
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM .Ecosystem_ecosystem__top__left__cDYkU {
    display: flex;
    flex-direction: column;
    gap: 23px
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM .Ecosystem_ecosystem__top__left__cDYkU h2 {
    color: #fff;
    font-size: 37.9px;
    font-weight: 700;
    text-transform: uppercase
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM .Ecosystem_ecosystem__top__left__cDYkU p {
    color: #898990
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM .Ecosystem_ecosystem__top__right__\+8ij\+ {
    align-items: start;
    display: flex
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM .Ecosystem_ecosystem__top__right__\+8ij\+button {
    align-items: center;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    font-weight: 300;
    height: 50px;
    justify-content: center;
    padding: 7px;
    position: relative;
    right: -15px;
    transition: all .3s ease-in-out;
    width: 50px
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__top__v0PrM .Ecosystem_ecosystem__top__right__\+8ij\+button:hover {
    scale: 1.1
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR {
    display: flex;
    gap: 29px;
    justify-content: space-between
}

@media(max-width:768px) {
    .Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR {
        flex-direction: column
    }
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__container__BeYrT {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__ZwTaD {
    align-items: start;
    background-color: #232325;
    border: 1px solid transparent;
    border-radius: 20px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: space-between;
    min-height: 156px;
    padding: 25px 40px;
    position: relative;
    transition: all .3s ease-in-out
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__ZwTaD h3 {
    color: #fff;
    font-size: extra-large-font-size2px;
    font-weight: 700
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__ZwTaD p {
    color: #898990
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__ZwTaD span {
    align-items: center;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-radius: .5rem;
    color: #fff;
    color: #232325;
    display: flex;
    font-size: 21px;
    height: 50px;
    justify-content: center;
    position: absolute;
    right: -17px;
    top: -27px;
    transition: all .3s ease-in-out;
    width: 50px
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__button__X4IuD {
    align-items: center;
    background: none;
    border: none;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    gap: .6rem;
    justify-content: center;
    transition: all .3s ease-in-out
}

.Ecosystem_ecosystem__Nm\+Hc .Ecosystem_ecosystem__bottom__zp3mR .Ecosystem_ecosystem__bottom__card__button__X4IuD:hover {
    opacity: .7
}

.ListBanner_listBanner__2\+HXE {
    align-items: center;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-radius: 20px;
    display: flex;
    height: 288px;
    justify-content: space-between;
    padding: 5rem;
    width: 1200px
}

@media(max-width:768px) {
    .ListBanner_listBanner__2\+HXE {
        align-items: start;
        flex-direction: column;
        gap: 1.5rem;
        height: auto !important;
        margin: 5rem 1rem 1rem;
        padding: 1.5rem;
        width: auto !important
    }
}

.ListBanner_listBanner__2\+HXE .ListBanner_listBanner__right__I3Ymp {
    display: flex;
    gap: 1rem
}

.ListBanner_listBanner__2\+HXE .ListBanner_listBanner__right__I3Ymp .ListBanner_listBanner__right__button__second__sRY8x {
    background: none;
    border: 2px solid #232325
}

.ListBanner_listBanner__2\+HXE .ListBanner_listBanner__right__I3Ymp .ListBanner_listBanner__right__button__first__zKt1B {
    background: #aa80f9;
    border: 2px solid #232325
}

.ListBanner_listBanner__2\+HXE h2 {
    color: #161617;
    font-size: 39px;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase
}

.Audit_audit__hQZUb {
    display: flex;
    flex-direction: column;
    gap: 77px;
    min-height: 400px
}

@media(max-width:768px) {
    .Audit_audit__hQZUb {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem
    }
}

.Audit_audit__hQZUb .Audit_audit_top__RCa\+V {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.Audit_audit__hQZUb .Audit_audit_top__RCa\+V .Audit_audit_top__left__2xugZ h2 {
    color: #fff;
    font-size: 39px;
    font-weight: 700;
    margin-bottom: 1rem
}

.Audit_audit__hQZUb .Audit_audit_top__RCa\+V .Audit_audit_top__left__2xugZ p {
    color: #898990
}

.Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i {
    align-items: center;
    display: flex;
    justify-content: space-between
}

@media(max-width:768px) {
    .Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i {
        flex-direction: column;
        gap: 2rem
    }
}

.Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i .Audit_audit_mid__title__TN6qf {
    color: #fff;
    font-size: 20px
}

.Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i .Audit_audit_mid__tokenAddress__container__Q2GZv {
    align-items: center;
    display: flex;
    gap: 1.2rem;
    justify-content: space-between
}

@media(max-width:768px) {
    .Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i .Audit_audit_mid__tokenAddress__container__Q2GZv {
        flex-direction: column;
        gap: 2rem
    }
}

.Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i .Audit_audit_mid__tokenAddress__container__Q2GZv .Audit_audit_mid__tokenAddress__NJVQN {
    align-items: center;
    border: 2px solid #fff;
    color: #fff;
    display: flex;
    font-size: 12px;
    gap: 1.2rem;
    justify-content: space-between;
    min-height: 54px;
    padding: 1rem
}

.Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i .Audit_audit_mid__tokenAddress__container__Q2GZv .Audit_audit_mid__tokenAddress__NJVQN .Audit_audit_mid__tokenAddress__copy__8kCZn {
    cursor: pointer;
    transition: all .1s ease-in-out
}

.Audit_audit__hQZUb .Audit_audit_mid__\+Vq9i .Audit_audit_mid__tokenAddress__container__Q2GZv .Audit_audit_mid__tokenAddress__NJVQN .Audit_audit_mid__tokenAddress__copy__8kCZn:hover {
    scale: 1.1
}

.Audit_audit__hQZUb .Audit_audit_bottom__Rjydh {
    display: flex;
    justify-content: space-around;
    margin: 0 6rem !important
}

@media(max-width:768px) {
    .Audit_audit__hQZUb .Audit_audit_bottom__Rjydh {
        flex-direction: column;
        gap: 6rem
    }
}

.Audit_audit__hQZUb .Audit_audit_bottom__Rjydh .Audit_audit_bottom__card__S5gnb {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center
}

.Audit_audit__hQZUb .Audit_audit_bottom__Rjydh .Audit_audit_bottom__card__S5gnb span {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    font-weight: 700;
    text-align: center
}

.BottomCards_bottomCards__Fl7Jp {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 4rem
}

@media(max-width:768px) {
    .BottomCards_bottomCards__Fl7Jp {
        grid-template-columns: repeat(1, 1fr);
        margin: 1rem
    }
}

.BottomCards_bottomCards__Fl7Jp .BottomCards_bottomCards_card__6tElS {
    background-color: #232325;
    border-radius: 10px;
    display: flex;
    gap: 2rem;
    padding: 2rem 1rem
}

.BottomCards_bottomCards__Fl7Jp .BottomCards_bottomCards_card__6tElS h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 700
}

.BottomCards_bottomCards__Fl7Jp .BottomCards_bottomCards_card__6tElS p {
    color: #898990;
    margin-top: 1rem
}

.RobotBanner_robotBanner__0U4xA {
    align-items: center;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-radius: 20px;
    display: flex;
    height: 484px;
    justify-content: space-between;
    padding: 3rem 4rem;
    width: 100%
}

@media(max-width:768px) {
    .RobotBanner_robotBanner__0U4xA {
        height: auto !important;
        left: 6rem;
        padding: 1.5rem;
        position: relative
    }
}

.RobotBanner_robotBanner__0U4xA .RobotBanner_robotBanner_right__42lhW {
    background-color: #ff0;
    position: relative;
    width: 30%
}

@media(max-width:768px) {
    .RobotBanner_robotBanner__0U4xA .RobotBanner_robotBanner_right__42lhW img {
        display: none
    }
}

.RobotBanner_robotBanner__0U4xA .RobotBanner_robotBanner_right__42lhW img {
    bottom: -300px;
    left: -40px;
    position: absolute;
    width: 480px
}

.RobotBanner_robotBanner__0U4xA .RobotBanner_robotBanner_left__Puwgi {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 70%
}

@media(max-width:768px) {
    .RobotBanner_robotBanner__0U4xA .RobotBanner_robotBanner_left__Puwgi {
        gap: 2rem
    }
}

.RobotBanner_robotBanner__0U4xA h2 {
    color: #161617;
    font-size: 39px;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.13
}

@media(max-width:768px) {
    .RobotBanner_robotBanner__0U4xA h2 {
        font-size: 24px
    }
}

.RobotBanner_robotBanner__0U4xA p {
    color: #000;
    font-weight: 700;
    line-height: 1.5
}

@media(max-width:768px) {
    .RobotBanner_robotBanner__0U4xA p {
        font-size: 14px
    }
}

.RobotBanner_robotBanner__0U4xA .RobotBanner_robotBanner_left__button__1iLAB {
    background-color: #aa80f9;
    border: 2px solid #161617;
    padding: 15px 25px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important
}

.PagesLayout_pagesLayout__HUOp- {
    margin-bottom: 116px;
    margin-top: 10rem;
    min-height: 700px;
    position: relative
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- {
        margin-top: 4rem !important
    }
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_headerVideo__V-NAQ {
        left: -300px !important
    }
}

.PagesLayout_pagesLayout__HUOp-:after {
    left: 50%;
    position: absolute;
    top: -160px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1
}

.PagesLayout_pagesLayout__HUOp-:before {
    /* content: url(./../js/assets//images/logos/ /Circle.526cf69afc8c722e2ff3.svg); */
    left: -25%;
    position: absolute;
    top: -300px;
    z-index: -1
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- {
        margin: 1rem 1rem 5rem
    }
    .PagesLayout_pagesLayout__HUOp-:after {
        top: -100px
    }
    .PagesLayout_pagesLayout__HUOp-:before {
        top: -200px
    }
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW {
    display: flex;
    flex-direction: row;
    gap: 22px
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW h2 {
        display: flex;
        flex-direction: column;
        font-size: 24px !important
    }
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW p {
        width: 100% !important
    }
}

@media(max-width:768px)and (max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW p {
        margin-right: 10rem !important;
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important
    }
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW h2 {
    color: #fff;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.18;
    text-transform: uppercase;
    width: 550px
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW p {
    color: #fff;
    line-height: 1.5;
    width: 526px
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__left__RREmL {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2.5rem
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__left__RREmL .PagesLayout_daoTopButtons__b03JK {
    display: flex;
    gap: 1rem
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__left__RREmL .PagesLayout_daoTopButtons__b03JK {
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 1rem
    }
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__left__RREmL .PagesLayout_daoTopButtons__b03JK button {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important
    }
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__right__ObW1u {
    flex: 1 1;
    position: relative
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__button__n8hiw {
    display: flex;
    gap: 85px
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__button__n8hiw .PagesLayout_pagesLayout_topContent__button__watchVideo__6Lvcr {
    align-items: center;
    color: #fff;
    display: flex;
    gap: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__button__n8hiw .PagesLayout_pagesLayout_topContent__button__watchVideo__6Lvcr img {
    cursor: pointer;
    transition: all .1s ease-in-out
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_topContent__AzUMW .PagesLayout_pagesLayout_topContent__button__n8hiw .PagesLayout_pagesLayout_topContent__button__watchVideo__6Lvcr img:hover {
    scale: 1.1
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    left: -20px;
    margin-top: 136px;
    position: relative
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP {
        flex-direction: column;
        gap: 2rem;
        left: auto;
        margin: 1rem
    }
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP .PagesLayout_pagesLayout_midContent__card__TwLy1 {
        width: 100%
    }
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP .PagesLayout_pagesLayout_midContent__card__TwLy1 {
    align-items: center;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    background: rgba(0, 5, 28, .251);
    border: 1px solid hsla(0, 0%, 50%, .341);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-align: center
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP .PagesLayout_pagesLayout_midContent__card__TwLy1 h2 {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.13;
    margin-bottom: 20px;
    white-space: nowrap
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP .PagesLayout_pagesLayout_midContent__card__TwLy1 p {
    color: #898990;
    font-size: .9rem;
    line-height: 1.5
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP .PagesLayout_pagesLayout_midContent__card__TwLy1 span {
    align-items: center;
    background-image: radial-gradient(circle at 50% 50%, hsla(0, 0%, 100%, 0) 15%, #24271b 71%);
    border-radius: 50%;
    display: flex;
    flex-grow: 0;
    height: 60px;
    justify-content: center;
    margin: 0 111.5px 30px;
    width: 60px
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_midContent__hMLuP .PagesLayout_pagesLayout_midContent__card__TwLy1 span p {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%)
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH {
    display: flex;
    gap: 30px;
    margin-top: 116px;
    width: 100%
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH img {
        display: none
    }
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH {
        flex-direction: column;
        gap: 2rem;
        margin-bottom: -5rem
    }
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH .PagesLayout_pagesLayout_bottomContent__left__85mde {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 572px
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH .PagesLayout_pagesLayout_bottomContent__lightTitle__iVzh\+ {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%)
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH .PagesLayout_pagesLayout_bottomContent__desc__XkyN7 {
    color: #898990;
    width: 90%
}

.PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH h2 {
    color: #fff;
    font-size: 39px;
    font-weight: 300;
    line-height: 1.18;
    text-transform: uppercase
}

@media(max-width:768px) {
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH h2 {
        display: flex;
        flex-direction: column;
        font-size: 24px !important
    }
    .PagesLayout_pagesLayout__HUOp- .PagesLayout_pagesLayout_bottomContent__5rnUH .PagesLayout_pagesLayout_bottomContent__desc__XkyN7 {
        width: 62% !important
    }
}

.PagesLayout_boldText__gyaXg {
    font-weight: 700 !important
}

.PagesLayout_infoWrapper__desc__list__miBH8 li {
    font-weight: 200
}

.PagesLayout_infoWrapper__MYKyS {
    margin-bottom: 4rem;
    margin-top: 121px;
    width: 80%
}

.PagesLayout_infoWrapper__MYKyS .PagesLayout_infoWrapper__title__tQKku {
    color: #fff;
    font-size: 39px;
    font-weight: 700;
    line-height: 42.64px;
    text-transform: uppercase
}

.PagesLayout_infoWrapper__MYKyS .PagesLayout_infoWrapper__desc__WwB0F {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.PagesLayout_becomeWrapper__yqoXG {
    background: #232325;
    border-radius: 10px;
    color: #fff;
    display: flex;
    margin-top: 21px;
    padding: 30px
}

@media(max-width:768px) {
    .PagesLayout_becomeWrapper__yqoXG {
        flex-direction: column;
        gap: 2rem
    }
}

.PagesLayout_becomeWrapper__yqoXG p {
    color: #898990;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 80%
}

.PagesLayout_becomeWrapper__yqoXG button {
    white-space: nowrap
}

.PagesLayout_becomeWrapper__yqoXG .PagesLayout_becomeWrapper__id__9Bu6H {
    align-items: center;
    background-image: linear-gradient(90deg, #fb6fbb 0, #ff9085 51%, #fa1191);
    border-radius: 50%;
    display: flex;
    height: 80px;
    justify-content: center;
    margin-right: 2rem;
    width: 80px
}

.PagesLayout_roles__QzAIS {
    grid-gap: 1.8rem;
    display: grid;
    gap: 1.8rem;
    grid-template-columns: repeat(2, 1fr)
}

@media(max-width:768px) {
    .PagesLayout_roles__QzAIS {
        grid-template-columns: repeat(1, 1fr)
    }
    .PagesLayout_roles__QzAIS .PagesLayout_becomeWrapper__yqoXG {
        align-items: start !important;
        gap: 2rem
    }
}

.PagesLayout_roles__QzAIS .PagesLayout_becomeWrapper__yqoXG {
    align-items: center;
    display: flex
}

.PagesLayout_roles__QzAIS ul {
    margin-left: 3rem;
    margin-top: 1rem
}

.PagesLayout_roles__QzAIS ul li {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: .5
}

.About_about__Qy8Me {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    position: relative
}

.About_about__Qy8Me:after {
    /* content: url(./../media/Circle.526cf69afc8c722e2ff3.svg); */
    position: absolute;
    top: 100px;
    z-index: -1
}

.About_about__Qy8Me .About_title__4W0dX {
    color: #fff;
    font-size: 39px;
    font-weight: 700;
    line-height: 1.13;
    text-transform: uppercase;
    width: 65%
}

.About_about__Qy8Me .About_desc__B3Z-f {
    color: #898990;
    width: 53%
}

.About_about__Qy8Me .About_about_top__ubk1j {
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.About_about__Qy8Me .About_about_middle__gkp0q {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    position: relative
}

.About_about__Qy8Me .About_about_middle__gkp0q .About_about_middle__left__X4Wfz {
    background-color: #ff0;
    flex-grow: 0;
    height: 534px;
    margin: 0 91px 0 0;
    width: 475px
}

.About_about__Qy8Me .About_about_middle__gkp0q .About_about_middle__right__QY46K {
    background-color: blue;
    flex-grow: 0;
    height: 534px;
    margin: 0 0 0 91px;
    position: absolute;
    right: -360px;
    width: 1043px
}

.About_about__Qy8Me .About_about_bottom__MQ4pX {
    margin-top: 179px
}

.About_about__Qy8Me .About_about_bottom__MQ4pX .About_slider__H\+s5H {
    background-color: #c4c4c4;
    flex-grow: 0;
    height: 458px;
    margin: 0 0 1px;
    width: 100%
}

.About_about__Qy8Me .About_about_bottom__MQ4pX .About_about_bottom__desc__mFUg8 {
    margin-bottom: 33px
}

.About_about__Qy8Me .About_about_bottom__MQ4pX .About_about_bottom__desc___other__OY7Q2 {
    color: #fff;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.13;
    margin-top: 2rem;
    text-transform: uppercase;
    width: 79% !important
}

.About_listBanner__PS\+mK {
    align-items: center;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-radius: 20px;
    display: flex;
    height: 288px;
    justify-content: space-between;
    margin-top: 171px;
    padding: 5rem;
    width: 1200px
}

.About_listBanner__PS\+mK .About_listBanner__right__TA-Uq {
    display: flex;
    gap: 1rem
}

.About_listBanner__PS\+mK .About_listBanner__right__TA-Uq .About_listBanner__right__button__second__XGUPn {
    background: none;
    border: 2px solid #232325
}

.About_listBanner__PS\+mK .About_listBanner__right__TA-Uq .About_listBanner__right__button__first__tk\+AM {
    background: #aa80f9;
    border: 2px solid #232325
}

.About_listBanner__PS\+mK .About_listBanner__left__A3ZhW {
    width: 60%
}

.About_listBanner__PS\+mK h2 {
    color: #161617;
    font-size: 39px;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase
}

.Carousel_wrapper__cy4V\+ {
    position: relative
}

.Carousel_arrow__dZvog {
    align-items: center !important;
    background: transparent !important;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex !important;
    font-size: 25px;
    font-size: 4rem !important;
    font-size: 20px;
    height: 45px;
    justify-content: center !important;
    line-height: 30px;
    opacity: .5;
    position: absolute;
    right: 0;
    text-align: center;
    width: 45px;
    z-index: 1000
}

.Carousel_direction__d7wUb {
    background-color: red !important
}

.Carousel_arrow_right__TLGkM {
    right: -50px !important;
    top: 50%
}

.Carousel_arrow_left__Lrbrc {
    left: -50px !important;
    top: 50%
}

.Carousel_item__NI7nF {
    border: 1px solid rgba(170, 0, 255, .422);
    border-radius: 2rem;
    box-shadow: 0 1px 2px rgba(197, 0, 251, .07), 0 2px 4px rgba(161, 2, 253, .07), 0 4px 8px rgba(174, 0, 254, .07), 0 8px 16px rgba(242, 13, 13, .07), 0 16px 32px rgba(153, 0, 255, .07), 0 32px 64px rgba(238, 0, 255, .07);
    color: #fff;
    font-size: 40px;
    height: 300px;
    text-align: center;
    transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
    transition: all .5s ease-in-out;
    width: 200px
}

.Carousel_textOverlay__GQ25x {
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 2rem;
    border-top: 1px solid gray;
    bottom: 0;
    cursor: pointer;
    display: flex;
    font-size: 1rem !important;
    gap: 10px;
    height: 170px;
    justify-content: center;
    left: 50%;
    padding-top: 10px;
    transition: all .5s ease-in-out;
    width: 100%
}

.Carousel_textOverlay__GQ25x .Carousel_textOverlay__name__aDxrW {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    width: 100%
}

.Carousel_textOverlay__GQ25x .Carousel_textOverlay__name__aDxrW * {
    line-height: normal
}

.Carousel_textOverlay__GQ25x .Carousel_textOverlay__name__aDxrW .Carousel_textOverlay__name__scoial__VM6m\+ {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0 15px
}

.Carousel_textOverlay__GQ25x .Carousel_textOverlay__name__aDxrW .Carousel_textOverlay__name__scoial__VM6m\+a {
    color: #fff;
    font-size: 2rem;
    text-decoration: none
}

.Carousel_item__NI7nF img {
    border-radius: 2rem;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.Carousel_level-2__8ISEo {
    height: 350px;
    left: 850px;
    line-height: 350px;
    margin-top: 25px;
    width: 460px
}

.Carousel_level-1__lopyj {
    height: 380px;
    left: 750px;
    line-height: 380px;
    margin-top: 10px;
    width: 480px
}

.Carousel_level0__6U7Ly {
    height: 400px;
    left: 630px;
    line-height: 400px;
    width: 500px
}

.Carousel_level1__PRK95 {
    height: 380px;
    left: 430px;
    line-height: 380px;
    margin-top: 10px;
    width: 480px
}

.Carousel_level2__3bfHI {
    height: 350px;
    left: 250px;
    line-height: 350px;
    margin-top: 25px;
    width: 460px
}

.Carousel_left-enter__nbeGJ {
    height: 320px;
    left: -210px;
    line-height: 320px;
    margin-top: 40px;
    opacity: 0;
    width: 440px
}

.Carousel_left-enter__nbeGJ.Carousel_left-enter-active__1vpae {
    height: 350px;
    left: 250px;
    line-height: 350px;
    margin-top: 25px;
    opacity: 1;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    width: 460px
}

.Carousel_left-leave__Te1-I {
    height: 350px;
    left: 850px;
    line-height: 350px;
    margin-top: 25px;
    opacity: 1;
    width: 460px
}

.Carousel_left-leave__Te1-I.Carousel_left-leave-active__rdwlh {
    height: 320px;
    left: 1330px;
    line-height: 120px;
    margin-top: 40px;
    opacity: 0;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    width: 440px
}

.Carousel_right-enter__nXKlL {
    height: 320px;
    left: 1310px;
    line-height: 320px;
    margin-top: 40px;
    opacity: 0;
    width: 440px
}

.Carousel_right-enter__nXKlL.Carousel_right-enter-active__kjA88 {
    height: 350px;
    left: 850px;
    line-height: 350px;
    margin-top: 25px;
    opacity: 1;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    width: 460px
}

.Carousel_right-leave__CMf\+C {
    height: 350px;
    left: 250px;
    line-height: 350px;
    margin-top: 25px;
    opacity: 1;
    width: 460px
}

.Carousel_right-leave__CMf\+C.Carousel_right-leave-active__K5z1O {
    height: 320px;
    left: -210px;
    line-height: 320px;
    margin-top: 40px;
    opacity: 0;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    width: 440px
}

.Carousel_noselect__3ETIp {
    -webkit-user-select: none;
    user-select: none
}

.Navbar_scrolled__ZsN9e {
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, .25), 0 3px 7px -3px rgba(0, 0, 0, .3) !important;
    transition: shadow .3s ease-in-out
}

.Navbar_navbar__47aAO {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: center;
    padding: 2rem 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: shadow .3s ease-in-out;
    width: 100%;
    z-index: 9999
}

.Navbar_navbar__47aAO .Navbar_navbar__container__IbCgl {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 1200px
}

@media(max-width:768px) {
    .Navbar_navbar__47aAO .Navbar_navbar__container__IbCgl {
        max-width: none;
        padding: 0 1rem;
        width: 100%
    }
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am,
.Navbar_navbar__47aAO .Navbar_navbar__right__104OW {
    flex: .8 1
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW {
    display: flex;
    justify-content: space-between;
    position: relative
}

@media(max-width:768px) {
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW {
        justify-content: end
    }
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW button {
        display: none
    }
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__6RwsL {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    position: absolute;
    right: 0;
    visibility: hidden
}

@media(max-width:768px) {
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__6RwsL {
        display: flex;
        position: relative;
        visibility: visible
    }
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__items__ot1h4 {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid #9c7bf2;
    border-radius: .5rem;
    color: #fff;
    display: flex;
    display: none;
    flex-direction: column;
    gap: 1rem;
    height: 60;
     /* .navbar .navbar__right .navbar__mobile__menu__items; */
    padding: .5rem;
    position: absolute;
    right: 0;
    top: 62px
}

@media(max-width:768px) {
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__items__ot1h4 {
        display: flex
    }
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__items__ot1h4 .Navbar_navbar__right__icons__YBDLG {
        display: flex;
        opacity: .6
    }
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__items__ot1h4 button {
        display: flex;
        white-space: nowrap
    }
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__items__ot1h4 .Navbar_navbar__mobile__menu__item__BlctA {
    cursor: pointer;
    position: relative
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__item__dropdown__pdE8S {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: hsla(0, 0%, 100%, .126);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: .8rem;
    padding: .5rem;
    text-decoration: none
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__item__dropdown__pdE8S a {
    color: #fff;
    font-size: 12px
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__mobile__menu__item__dropdown__pdE8S a:hover {
    opacity: .8
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__right__icons__YBDLG {
    align-items: center;
    display: flex;
    gap: 24px
}

@media(max-width:768px) {
    .Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__right__icons__YBDLG {
        display: none
    }
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__right__icons__YBDLG a {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all .3s ease-in-out
}

.Navbar_navbar__47aAO .Navbar_navbar__right__104OW .Navbar_navbar__right__icons__YBDLG a:hover {
    opacity: .8
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am {
    align-items: center;
    display: flex;
    flex: 2 1 !important;
    gap: 3rem
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__logo__XpSbI {
    align-items: center;
    display: flex
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u {
    display: flex;
    gap: 1rem
}

@media(max-width:768px) {
    .Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u {
        display: none
    }
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u .Navbar_navbar__left__item__yO6GN {
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    gap: 2px;
    position: relative;
    transition: all .3s ease-in-out;
    transition: all .1s ease-in-out
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u .Navbar_navbar__left__item__yO6GN .Navbar_navbar__left__item__dropdown__2lipB {
    --borderWidth: 3px;
    background-color: #232325;
    border-radius: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    left: 0;
    min-width: 230px;
    padding: 1rem;
    position: absolute !important;
    top: 30px;
    transition: all .3s ease-in-out;
    transition: all .1s ease-in-out
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u .Navbar_navbar__left__item__yO6GN .Navbar_navbar__left__item__dropdown__2lipB .Navbar_navbar__left__item__dropdown__item__link__1nrMG .Navbar_navbar__left__item__dropdown__item__ymI6w {
    color: #898990 !important;
    transition: all .3s ease-in-out;
    white-space: nowrap
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u .Navbar_navbar__left__item__yO6GN .Navbar_navbar__left__item__dropdown__2lipB .Navbar_navbar__left__item__dropdown__item__link__1nrMG .Navbar_navbar__left__item__dropdown__item__ymI6w:hover {
    color: #fff !important;
    opacity: .8
}

.Navbar_navbar__47aAO .Navbar_navbar__left__Ms9Am .Navbar_navbar__left__items__6WF3u .Navbar_navbar__left__item__yO6GN .Navbar_navbar__left__item__dropdown__2lipB:after {
    -webkit-animation: Navbar_animatedgradient__mZ2tG 3s ease infinite alternate;
    animation: Navbar_animatedgradient__mZ2tG 3s ease infinite alternate;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 300% 300%;
    border-radius: 6px;
    content: "";
    height: calc(100% + var(--borderWidth)*2);
    left: calc(var(--borderWidth)*-1);
    position: absolute;
    top: calc(var(--borderWidth)*-1);
    width: calc(100% + var(--borderWidth)*2);
    z-index: -1
}

@-webkit-keyframes Navbar_animatedgradient__mZ2tG {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    to {
        background-position: 0 50%
    }
}

@keyframes Navbar_animatedgradient__mZ2tG {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    to {
        background-position: 0 50%
    }
}

.Footer_footer__1WHRo {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    max-width: 1200px;
    min-height: 800px;
    position: relative;
    width: 100%
}

.Footer_footer__1WHRo:after {
    bottom: 0;
    content: url(./../media/Footer.a05bfae82c0a96e23f88.svg);
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 93px
}

@media(max-width:768px) {
    .Footer_footer__1WHRo .Footer_footer_top__4kV68 {
        flex-direction: column;
        gap: 56px;
        margin: 1rem
    }
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ {
    align-items: center;
    background-color: #3d3d3e;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 21px;
    height: auto;
    padding: 21px;
    width: 680px
}

@media(max-width:768px) {
    .Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ {
        height: auto !important;
        width: 100%
    }
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ textarea {
    background-color: #232325;
    border: none !important;
    border-radius: 10px;
    color: #fff;
    font-family: Space Grotesk;
    padding: 1rem;
    resize: none;
    width: 100%
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ textarea:focus {
    outline: none
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ input {
    background-color: #232325;
    border: none !important;
    border-radius: 10px;
    color: #fff;
    flex-grow: 0;
    font-family: Space Grotesk;
    height: 60px;
    padding: 1rem;
    width: 100%
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ input:focus {
    outline: none
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ .Footer_footer_top__right__form__qvNBG {
    display: flex;
    gap: 21px;
    width: 100%
}

@media(max-width:768px) {
    .Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__right__mO4DJ .Footer_footer_top__right__form__qvNBG {
        flex-direction: column
    }
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__text__xEHR- {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__social__SQ7tT {
    align-items: center;
    color: #fff;
    display: flex;
    gap: 3rem;
    justify-content: center
}

@media(max-width:768px) {
    .Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__social__SQ7tT {
        margin-top: 1rem
    }
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__social__SQ7tT .Footer_footer_top__left__social__icons__sOzWX {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: center
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__social__SQ7tT .Footer_footer_top__left__social__icons__sOzWX a {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all .3s ease-in-out
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__social__SQ7tT .Footer_footer_top__left__social__icons__sOzWX a:hover {
    opacity: .8
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__title__smLmk {
    color: #fff;
    font-size: 39px;
    font-weight: 700;
    line-height: 1.13;
    text-transform: uppercase
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar .Footer_footer_top__left__desc__j06vT {
    color: #898990;
    line-height: 1.5
}

.Footer_footer__1WHRo .Footer_footer_top__4kV68 .Footer_footer_top__left__xm9Ar a {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    font-weight: 700;
    text-align: center
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO {
    border-top: 4px dashed #545454;
    display: flex;
    justify-content: space-between;
    padding-bottom: 93px;
    padding-top: 93px
}

@media(max-width:768px) {
    .Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO {
        flex-direction: column;
        gap: 4rem;
        margin: 1rem
    }
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__first__MA1to,
.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__first__MA1to a {
    color: #898990
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__first__MA1to p {
    margin-top: 1rem
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__second__K9CeV {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__second__K9CeV a {
    color: #898990;
    font-size: 14px;
    line-height: 1.29;
    transition: all .3s ease-in-out
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__second__K9CeV a:hover {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%);
    border-image-source: linear-gradient(270deg, #ff9085 30%, #fb6fbb 99%)
}

.Footer_footer__1WHRo .Footer_footer_bottom__Z2NBO .Footer_footer_bottom__second__K9CeV span {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    margin-bottom: .5rem;
    text-transform: uppercase
}

.Layout_layout__9\+tti {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.Layout_layout__9\+tti .Layout_mainContent__P9UoH {
    max-width: 1200px;
    width: 100%
}

@media(max-width:768px) {
    .Layout_layout__9\+tti {
        overflow: hidden !important
    }
}

* {
    box-sizing: border-box !important;
    margin: 0;
    padding: 0
}

html {
    background-color: #00051c
}

body {
    font-family: Space Grotesk, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    overflow-x: hidden !important;
    padding: 0
}

a {
    text-decoration: none
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px
}

.wrapper {
    background-color: red !important;
    position: relative
}

.arrow {
    background-color: #fff;
    border-radius: 50%;
    color: #228291;
    cursor: pointer;
    font-size: 25px;
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 1000
}

.direction {
    background-color: red !important
}

.arrow_right {
    right: -50px !important;
    top: 50%
}

.arrow_left {
    left: -50px !important;
    top: 50%
}

.item {
    background-color: #ff0 !important;
    color: #fff;
    font-size: 40px;
    height: 300px;
    text-align: center;
    transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
    width: 200px
}

.level-2 {
    background-color: #228291;
    height: 375px;
    left: 975px;
    line-height: 375px;
    margin-top: 25px;
    width: 165px
}

.level-1 {
    background-color: #6796e5;
    height: 270px;
    left: 750px;
    line-height: 270px;
    margin-top: 10px;
    width: 195px
}

.level0 {
    background-color: #4ec9e1;
    height: 500px;
    left: 495px;
    line-height: 500px;
    width: 225px
}

.level1 {
    background-color: #6796e5;
    height: 270px;
    left: 270px;
    line-height: 270px;
    margin-top: 10px;
    width: 195px
}

.level2 {
    height: 375px;
    left: 75px;
    line-height: 375px;
    margin-top: 25px;
    width: 165px
}

.left-enter {
    height: 345px;
    left: -90px;
    line-height: 345px;
    margin-top: 40px;
    opacity: 0;
    width: 145px
}

.left-enter.left-enter-active {
    left: 75px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s
}

.left-enter.left-enter-active,
.left-leave {
    height: 375px;
    line-height: 375px;
    margin-top: 25px;
    opacity: 1;
    width: 165px
}

.left-leave {
    left: 975px
}

.left-leave.left-leave-active {
    left: 1160px;
    line-height: 120px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s
}

.left-leave.left-leave-active,
.right-enter {
    height: 345px;
    margin-top: 40px;
    opacity: 0;
    width: 145px
}

.right-enter {
    left: 1140px;
    line-height: 345px
}

.right-enter.right-enter-active {
    left: 975px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s
}

.right-enter.right-enter-active,
.right-leave {
    height: 375px;
    line-height: 375px;
    margin-top: 25px;
    opacity: 1;
    width: 165px
}

.right-leave {
    left: 75px
}

.right-leave.right-leave-active {
    height: 345px;
    left: -90px;
    line-height: 345px;
    margin-top: 40px;
    opacity: 0;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    width: 145px
}

.noselect {
    -webkit-user-select: none;
    user-select: none
}

label {
    color: #78798a;
    font-size: 14px;
    font-weight: 500
}

.primary {
    color: #0056f8
}

.secondary {
    color: #000
}

.light {
    color: #fff
}

.center-content {
    align-items: center;
    display: flex;
    justify-content: center
}

.full-height {
    height: 100vh
}

.text-right {
    text-align: right
}

.text-left {
    text-align: left
}

.clearfix:after,
.clearfix:before {
    clear: both;
    content: "";
    display: table
}

.gap-2 {
    gap: .5rem
}

.gap-3 {
    gap: .75rem
}

.gap-4 {
    gap: 1rem
}

.gap-5 {
    gap: 1.25rem
}

.gap-6 {
    gap: 1.5rem
}

.gap-7 {
    gap: 1.75rem
}

.gap-8 {
    gap: 2rem
}

.gap-9 {
    gap: 2.25rem
}

.gap-10 {
    gap: 2.5rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-4 {
    margin-top: 1.5rem
}

.mb-1 {
    margin-bottom: .25rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mb-4 {
    margin-bottom: 1.5rem
}

.ml-1 {
    margin-left: .25rem
}

.ml-2 {
    margin-left: .5rem
}

.ml-3 {
    margin-left: .75rem
}

.mr-1 {
    margin-right: .25rem
}

.mr-2 {
    margin-right: .5rem
}

.mr-3 {
    margin-right: .75rem
}

.pt-1 {
    padding-top: .25rem
}

.pt-2 {
    padding-top: .5rem
}

.pt-3 {
    padding-top: .75rem
}

.pb-1 {
    padding-bottom: .25rem
}

.pb-2 {
    padding-bottom: .5rem
}

.pb-3 {
    padding-bottom: .75rem
}

.pl-1 {
    padding-left: .25rem
}

.pl-2 {
    padding-left: .5rem
}

.pl-3 {
    padding-left: .75rem
}

.pr-1 {
    padding-right: .25rem
}

.pr-2 {
    padding-right: .5rem
}

.pr-3 {
    padding-right: .75rem
}

.text-center {
    text-align: center
}

.text-bold {
    font-weight: 700
}

.text-uppercase {
    text-transform: uppercase
}

.d-block {
    display: block
}

.d-inline {
    display: inline
}

.d-inline-block {
    display: inline-block
}

.position-relative {
    position: relative
}

.position-absolute {
    position: absolute
}

.position-fixed {
    position: fixed
}

.text-6xl {
    font-size: 4rem
}

.text-5xl {
    font-size: 3rem
}

.text-4xl {
    font-size: 2.25rem
}

.text-3xl {
    font-size: 1.875rem
}

.text-2xl {
    font-size: 1.5rem
}

.text-xl {
    font-size: 1.25rem
}

.text-lg {
    font-size: 1.125rem
}

.text-base {
    font-size: 1rem
}

.text-sm {
    font-size: .875rem
}

.text-xs {
    font-size: .75rem
}

.layoutSpace {
    padding: 0 5.4rem
}

.flex-1 {
    max-width: calc(8.33333% - 30px)
}

.flex-1,
.flex-2 {
    min-width: 0 !important;
    min-width: auto !important
}

.flex-2 {
    max-width: calc(16.66667% - 30px)
}

.flex-3 {
    max-width: calc(25% - 30px)
}

.flex-3,
.flex-4 {
    min-width: 0 !important;
    min-width: auto !important
}

.flex-4 {
    max-width: calc(33.33333% - 30px)
}

.flex-5 {
    max-width: calc(41.66667% - 30px)
}

.flex-5,
.flex-6 {
    min-width: 0 !important;
    min-width: auto !important
}

.flex-6 {
    max-width: calc(50% - 30px)
}

.flex-7 {
    max-width: calc(58.33333% - 30px)
}

.flex-7,
.flex-8 {
    min-width: 0 !important;
    min-width: auto !important
}

.flex-8 {
    max-width: calc(66.66667% - 30px)
}

.flex-9 {
    max-width: calc(75% - 30px)
}

.flex-10,
.flex-9 {
    min-width: 0 !important;
    min-width: auto !important
}

.flex-10 {
    max-width: calc(83.33333% - 30px)
}

.flex-11 {
    max-width: calc(91.66667% - 30px)
}

.flex-11,
.flex-12 {
    min-width: 0 !important;
    min-width: auto !important
}

.flex-12 {
    max-width: calc(100% - 30px)
}

.flex-end {
    margin-left: auto !important
}

.flex-small {
    max-width: calc(3.5% - 30px);
    min-width: 0 !important;
    min-width: auto !important
}

::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-track {
    border-radius: 10px
}

::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 66%, .334);
    border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
    background: gray
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: hsla(240, 7%, 68%, .9) !important
}

.ReactModal__Content.ReactModal__Content--after-open {
    background: #fff;
    border: none;
    border-radius: 4px;
    inset: 60px 0 40px;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(-50px + 100vh);
    max-width: calc(-400px + 100vw);
    outline: none;
    overflow: hidden;
    padding: 10px 20px 20px;
    position: absolute
}

.select {
    border: 1px solid #e4e4e4;
    border-radius: 4px !important
}

.select .custom_select__control {
    background: #fff;
    border: 2px solid transparent;
    box-shadow: none;
    height: 30px;
    min-height: 30px
}

.select .custom_select__control .custom_select__value-container {
    font-size: 12px;
    font-weight: 400;
    opacity: .4
}

.select .custom_select__control .custom_select__single-value {
    color: #314469;
    font-size: 12px;
    font-weight: 400
}

.select .custom_select__control .custom_select__input-container .custom_select__input {
    color: #314469 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    outline: none !important
}

.select .custom_select__control .custom_select__multi-value {
    background: #deebff;
    color: #0056f8;
    margin: 0 4px 0 0
}

.select .custom_select__control .custom_select__multi-value .custom_select__multi-value__label {
    color: #0056f8
}

.select .custom_select__control .custom_select__multi-value .custom_select__multi-value__remove {
    cursor: pointer
}

.select .custom_select__control .custom_select__multi-value .custom_select__multi-value__remove:hover {
    background-color: #0056f8;
    color: #fff
}

.select .custom_select__control .custom_select__indicators {
    cursor: pointer;
    max-height: 36px
}

.select .custom_select__control .custom_select__indicators .custom_select__indicator-separator {
    display: none
}

.select .custom_select__control .custom_select__indicators .custom_select__indicator {
    padding: 4px
}

.select .custom_select__control .custom_select__indicators .custom_select__indicator svg {
    color: #000;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.select.custom_select__control--is-focused {
    background: #eafff4 0 0 no-repeat padding-box !important;
    border: 2px solid #00b735 !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 2px rgba(0, 230, 133, .502) !important;
    opacity: 1 !important
}

.custom_select__menu {
    border-radius: 4px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .102);
    overflow: hidden;
    z-index: 3
}

.custom_select__menu .custom_select__menu-list {
    padding: 0
}

.custom_select__menu .custom_select__menu-notice,
.custom_select__menu .custom_select__menu-notice--no-options,
.custom_select__menu .custom_select__option {
    color: #314469 !important;
    cursor: pointer;
    font-size: 12px !important;
    font-weight: 400 !important;
    outline: none !important
}

.custom_select__menu .custom_select__option.custom_select__option--is-selected {
    color: #fff !important
}

.Notification_notification__E9RAU {
    align-items: center;
    background-color: #00410b;
    border: 2px solid #2aff00;
    border-radius: 1rem;
    color: #2aff00;
    display: flex;
    font-size: 1.2rem;
    margin-top: 80px;
    min-height: 110px;
    min-width: 300px;
    padding-right: 6rem !important;
    padding: 1rem;
    position: fixed;
    right: 30px;
    top: 0;
    z-index: 9999
}

.Notification_danger__Bwz9Z {
    background-color: #4a0000;
    border: 2px solid red;
    color: red
}

.Notification_notification__E9RAU:before {
    background-image: url(./../js/assets/images/Robot.svg);
    background-position: right 0 top 0;
    background-repeat: no-repeat;
    background-size: 40%;
    content: "";
    -webkit-filter: grayscale(100%) opacity(.9);
    filter: grayscale(100%) opacity(.9);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 99999;
    z-index: -1
}

.Pricing_container__UO7vU {
    grid-gap: 16px;
    background-color: #232325;
    border-radius: 20px;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    margin: 2rem 0;
    padding: 1rem
}

@media(max-width:768px) {
    .Pricing_container__UO7vU {
        grid-template-columns: repeat(1, 1fr)
    }
}

.Pricing_container__UO7vU .Pricing_card__68oik {
    align-items: center;
    background: #1f1f1f;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    height: 452px;
    justify-content: space-between;
    padding: 2rem
}

@media(max-width:768px) {
    .Pricing_container__UO7vU .Pricing_card__68oik {
        width: 100%
    }
}

.Pricing_container__UO7vU .Pricing_card__68oik .Pricing_cardContent__wM0dK {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    width: 80%
}

.Pricing_container__UO7vU .Pricing_card__68oik .Pricing_cardContent__wM0dK .Pricing_credit__\+dXYy {
    color: #757575;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -.36px;
    line-height: 24px
}

.Pricing_container__UO7vU .Pricing_card__68oik .Pricing_cardContent__wM0dK h2 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -.48px;
    line-height: 32px;
    text-align: center
}

.Pricing_container__UO7vU .Pricing_card__68oik .Pricing_cardContent__wM0dK span {
    align-items: center;
    display: flex;
    gap: .3rem;
    justify-content: center
}

.Pricing_container__UO7vU .Pricing_card__68oik .Pricing_cardContent__wM0dK span strong {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: -2.2px;
    line-height: 52px
}

.Pricing_container__UO7vU .Pricing_card__68oik button {
    background-image: linear-gradient(90deg, #fb6fbb 0, #ff9085 51%, #fa1191);
    background-size: 200% auto;
    border: none;
    border-radius: 16px;
    color: #151517;
    color: #fff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    height: 70px;
    padding: 0 2rem;
    text-align: center;
    text-transform: uppercase;
    transition: .5s;
    width: 80%
}

/*# sourceMappingURL=main.1b01af96.css.map*/