.container-history-trade {
  text-align: center;
  margin: 0px 0px 0px 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.data-table-history-trade {
  margin: auto;
  border-collapse: collapse;
  width: 99%;
  margin-top: 10px;
  table-layout: fixed;

  tr {
    border-bottom: 1px solid #25292f; /* Đường line mảnh giữa mỗi hàng */
  }

  th {
    font-size: 12px;
    text-align: left;
    background-color: solid transparent;
    color: #808080;
    position: relative;
  }

  thead tr {
    border-bottom: 1px solid #25292f; /* Đường line đậm giữa hàng đầu tiên và thead */
  }

  thead th:nth-child(1) {
    width: 13%; /* Thay đổi giá trị width tùy ý */
  }
  
  thead th:nth-child(2) {
    width: 13%; /* Thay đổi giá trị width tùy ý */
  }
  
  thead th:nth-child(3) {
    width: 15%; /* Thay đổi giá trị width tùy ý */
  }

  thead th:last-child {
    width: 5%;
  }

  tbody tr {
    height: 39px;
  }

  tbody td:last-child {
    text-align: center;
  }

  tbody tr td {
    color:  #dbdee1;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    align-items: center;

    .price:hover {
      width: 13.5px;
      height: 13.5px;
    }
  }
}

.data-table-history-trade td button {
  background-color: transparent;
  color: white;
  border: none;
  /* padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.data-table-history-trade td button img:hover {
  /* background-color: #d32f2f; */
width: 20px;
height: 20px;
}

.dropdown {
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  justify-content: center;
   color: #808080;
  /* padding: 8px; */
  font-size: 14px;
  border: none;
  cursor: pointer;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1e2328;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  border-width: 1px solid #282e34;

  .selected-dropdown {
    color: #2954ff;
  }
}

.dropdown-content span {
  color: #e9ecef;
  padding: 6px 16px;
  /* text-decoration: none; */
  display: block;
}

.dropdown-content span:hover {background-color: #8a9098}

.dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 600px) {
  .data-table-history-trade thead {
    display: none;
    /* Ẩn phần tiêu đề khi màn hình nhỏ */
  }

  .data-table-history-trade tbody tr {
    height: 175px;
    display: block;
  }

  .data-table-history-trade tbody td {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 14px;
    text-align: left;
  }

  .data-table-history-trade tbody td:before {
    content: attr(data-label);
    /* Thêm nhãn cột vào trước nội dung */
    font-weight: bold;
    color: #808080;
  }

  .data-table-history-trade tbody tr:first-child {
    border-top: none;
    /* Ẩn đường viền cho ô cuối cùng */
  }
}