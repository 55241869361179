.edit_future_view{
    background: #191919;
    border-radius: 5px;
    padding: 0px 15px 15px;
    position: absolute;
    z-index: 1000;
    width: 100%;
    min-width: 300px;
  }

  .container {
    p {
       font-size: 15px;
       color: #a6acb5;
       
    }
    margin-bottom: 20px;
   }
   
   .pBold {
       font-weight: 600;
       color: #52c08f;
   }
   
   .between-space {
       display: flex;
       justify-content: space-between;
       margin-top: 5px;
   }
   
   .divider {
       height: 1px;
       width: 100%;
       background-color: #fff;
       margin: 10px 0px;
   }
   
   .slider {
       margin-top: 35px;
   }

   .input_enable_edit {
    font-weight: 600;
    background-color: black;
    color: white;
    padding: 5px;
    max-width: 100px; /* Thiết lập max-width */
    width: auto;
    display: inline-block;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
   }

   .edit-future {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
   }

   .edit-future button {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
   }