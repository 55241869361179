.input_focus {
    border: 0.5px solid white;
}

.inputAmount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 52px;
    width: 100%;
    padding: 7px 0px 8px 9px;
    border-radius: 5px;
    background-color: #222222;
    margin-bottom: 30px;

    label {
        text-align: left;
        /* color: #e9e9e9; */
        font-weight: 100;
        width: 100%;
        padding-bottom: 2px;
        font-size: 12px;
    }

    input {
        background-color: #222222;
        border: 0px;
        outline: none;
        /* color: white; */
        font-size: 16px;
        font-weight: 400;
    }

    input[type="number"] {
        -webkit-appearance: textfield !important;
        -moz-appearance: textfield !important;
        appearance: textfield !important;
    }
    
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}

.inputAmount:hover {
    border: 0.5px solid white;
}

.content-unit {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    input {
        width: 100%;
    }
    
    .p-unit {
        font-weight: 600;
        padding-right: 8px;
        padding-left: 8px;
        color: #a6acb5
    }
}