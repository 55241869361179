.container {
  width: '100%'
}

.button-group-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  border: 0px;
  /* border-color: solid transparent; */
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.button:hover {
  color: white;
}

.button-1 {
  background-color: #222222;
  color: #808080;
}

.button-2 {
  background-color: #222222;
  color: #808080;
}

.button-1.active {
  background-color: #1fab95;
  color: white;
}

.button-2.active {
  background-color: #e2464a;
  color: white;
}

.tab {
  color: red;
  font-size: 8px;
}