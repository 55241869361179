.login-form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.login-form-group {
    background-color: #212529;
    width: 300px;
    height: 300px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-element {
    width: 80%;
    height: 80%;
    margin-top: 25%;

    input {
        width: 100%;
        padding: 0.5em;
        font-size: 14px;
        box-sizing: border-box;
        background: #2e2e2e;
        border: none;
        border-radius: 5px;
        padding: 10px;
        color: #ffffff;
        margin-bottom: 15px;
    }

    label {
        display: block;
        margin-bottom: 0.5em;
        text-align: left;
    }
}

.login-action {
    margin-top: 15%;
    display: flex;
    justify-content: space-between;
}
