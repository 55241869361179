.notification-detail {
    position: absolute;
    // border: 2px solid #000;
    border: none;
    background-color: #343A40;
    box-shadow: 2px solid black;
    height: 300px;
    width: 600px;
    margin: auto;
    padding: 2%;
    color: white;
    border-radius: 10px;
}

.notification {
    display: block;
    margin-bottom: 10px;
}

.notification-detail-title {
    font-size: 40px;
}

@media (max-width: 800px) {
    .notification-detail {
        height: 200px;
        width: 400px; 
    }
    .notification-detail-title {
        font-size: 20px;
    }
    .notification {
        font-size: 10px;
    }
}

@media (max-height: 400px) {
    .notification-detail {
        height: 200px;
        width: 400px; 
    }
    .notification-detail-title {
        font-size: 20px;
    }
    .notification {
        font-size: 10px;
    }
}
