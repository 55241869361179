@import './../../../css/styles/variables';
@import "./../../../css/styles/mixins";
@import "./../../../css/styles/_global.scss";
@import "./../../../css/main.1b01af96.css";


.container_login {
  box-shadow: 0px 0px 16px 4px rgba(10, 10, 10, 0.5);
  /* Tạo hiệu ứng bóng đổ */
  padding: 20px
}

.container_login>* {
  padding: 5px;


}

.container_login .sub_login {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;

  p {
    font-size: $small-font-size;
  }

  p:hover {
    background-image: $gradient-color;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }

}

.container_login .login_google .button_login_gg {
  background-color: #fff;
  height: 45px;
  display: flex;
  border-radius: 5px;
  // align-items: center;

  justify-content: center;
  // justify-content: space-between; /* Căn giữa và tạo khoảng trống ở hai bên */
  align-items: center; /* Căn phần tử con theo chiều dọc */
  padding: 0 15px;
  color: black;
 

  .icon_gg {
    width: 28px;
    height: 28px;
    margin-right: 10px;

  }
}

.container_login .login_google {
  // background-color: aqua;
  display: grid;
  align-items: center;
  // place-items: center; 
  // justify-content: center;
  // text-align: center;
}

.btn_grad {
  width: 100%;
  background-image: $gradient-color;
  padding: 15px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: $secondary-color;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 600;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
}