.container {
  width: 99vw; /* Chiều rộng bằng với chiều rộng của viewport */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.horizontal {
  display: flex;

  .chartView {
    width: 70%; /* Mặc định chiếm 70% trên màn hình lớn */
  }

  .tradeView {
    width: 30%; /* Mặc định chiếm 30% trên màn hình lớn */
    background-color: #0e0e0e;
    border-radius: 5px;

    .hihi {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 44px;
      background-color: aqua;

      .tab {
        cursor: pointer;
        padding: 10px 20px;
        color: red;
        background-color: red;
      }

      .tab.active {
        color: white;
        background-color: #333;
        border-bottom: 4px solid cyan;
      }

      .tabContent {
        margin-top: 20px;
      }
    }
  }
}

.horizontal > * {
  padding-left: 5px;
  padding-right: 5px;
}

/* Responsive: Layout dọc và full width trên màn hình nhỏ */
@media (max-width: 830px) {
  .horizontal {
    display: block; /* Sắp xếp các phần tử theo chiều dọc */
    .chartView, .tradeView {
      width: 100%; /* Chiếm toàn bộ chiều rộng */
      margin-bottom: 20px; /* Khoảng cách giữa chart và switchTab */
    }
  }

  

  .tradeView .hihi {
    flex-direction: column; /* Tab chuyển thành dạng cột nếu cần */
  }
}
