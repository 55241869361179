.crypto {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.crypto-box {
    border-radius: 5%;
    background-color: #0e0e0e;
    width: 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 0px;
    border: 0.1px solid #fc76ae80
}

.spend:hover {
    border: 0.5px solid white;
}

.spend:focus-within {
    border: 0.5px solid white;
}

.spend {
    display: flex;
    align-items: flex-start;
    height: 52px;
    width: 80%;
    padding: 7px 0px 8px 9px;
    border-radius: 5px;
    background-color: #222222;
    margin-bottom: 30px;

    select {
        border-radius: 5px;
        height: 100%;
        width: 30%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        border: none;
        outline: none;
        background-color: #222222;
        color: #e9e9e9;
    }
}

.spend-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        text-align: left;
        color: #e9e9e9;
        font-weight: 100;
        width: 70%;
        padding-bottom: 2px;
        font-size: 12px;
    }

    input {
        height: 100%;
        width: 70%;
        border: none;
        outline: none;
        text-align: left;
        font-size: 16px;
        background-color: #222222;
        color: white;
        flex-direction: column;
    }

    input::placeholder {
        font-size: 16px
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
}

.select-options {
    width: 300px;
    padding-right: 10px;
}

.bank {
    font-size: 30px;
    color: white;
    background-color:darkslategray;
    border-radius: 5px;
}

.bank-account {
    font-size: 25px;
    color: white;
    background-color:darkslategray;
    border-radius: 5px;
    margin-bottom: 50px;
}

.coinName {
    width: 80%;
    margin-bottom: 20px ;

    p {
        color: #a6acb5;
    }

    .title {
        color: #fff;
    }

    .input-file {
        width: 100%;
        color: #fff;
    }
}

.coinName > * {
    margin: 10px 0px;
}

/* Media queries cho tablet (màn hình vừa) */
@media (max-width: 1024px) {
    .crypto-box {
        width: 60%;
    }

    .bank, .bank-account {
        font-size: 24px;
    }

    .spend {
        width: 90%;
    }

    .spend-input input {
        width: 90%;
    }
}

/* Media queries cho mobile (màn hình nhỏ) */
@media (max-width: 768px) {
    .crypto-box {
        width: 80%;
    }

    .bank, .bank-account {
        font-size: 20px;
    }

    .spend {
        width: 100%;
    }

    .spend-input input {
        width: 100%;
    }

    .coinName {
        width: 90%;
    }

    .select-options {
        width: 100%;
    }
}
