@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.footer {
  min-height: 350px;
  width: 100%;
  max-width: $max-width;
  margin-top: 8rem;
  position: relative;
  flex-direction: column;
  display: flex;
  &::after {
    content: url("../../../media/Footer.a05bfae82c0a96e23f88.svg");
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .footer_top {
    padding-bottom: 93px;
    display: flex;
    justify-content: space-between;
    @include respond-to(tablet-p) {
      flex-direction: column;
      gap: 56px;
      margin: 1rem;
    }
    .footer_top__right {
      padding: 21px;
      width: 680px;
      height: auto;
      background-color: #3d3d3e;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 21px;
      @include respond-to(tablet-p) {
        width: 100%;
        height: auto !important;
      }
      textarea {
        width: 100%;
        resize: none;
        padding: 1rem;
        border-radius: 10px;
        background-color: #232325;
        border: none !important;
        color: $light-color;
        font-family: "Space Grotesk";
        &:focus {
          outline: none;
        }
      }

      input {
        font-family: "Space Grotesk";
        height: 60px;
        padding: 1rem;

        flex-grow: 0;
        color: $light-color;
        border-radius: 10px;
        background-color: #232325;
        width: 100%;
        border: none !important;
        &:focus {
          outline: none;
        }
      }
      .footer_top__right__form {
        gap: 21px;
        display: flex;
        width: 100%;

        @include respond-to(tablet-p) {
          flex-direction: column;
        }
      }
    }
    .footer_top__left {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .footer_top__left__text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: start;
      }

      .footer_top__left__social {
        display: flex;
        align-items: center;
        color: $light-color;
        justify-content: center;
        gap: 3rem;
        @include respond-to(tablet-p) {
          margin-top: 1rem;
        }
        .footer_top__left__social__icons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          a {
            @include flexCenter();

            transition: all 0.3s ease-in-out;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .footer_top__left__title {
        font-size: $card-title-font-size;
        font-weight: $bold-weight;
        line-height: 1.13;
        color: $light-color;
        text-transform: uppercase;
      }
      .footer_top__left__desc {
        line-height: 1.5;
        color: $card-desc-color;
      }
      a {
        border-image-source: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        background-image: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        font-weight: $bold-weight;
        text-align: center;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .footer_bottom {
    padding-top: 93px;
    padding-bottom: 93px;
    border-top: 4px dashed $dashed-color;
    display: flex;
    justify-content: space-between;
    @include respond-to(tablet-p) {
      flex-direction: column;
      gap: 4rem;
      margin: 1rem;
    }
    .footer_bottom__first {
      color: $card-desc-color;
      a {
        color: $card-desc-color;
      }
      p {
        margin-top: 1rem;
      }
    }
    .footer_bottom__second {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      a {
        line-height: 1.29;
        font-size: $small-font-size;
        color: $card-desc-color;
        transition: all 0.3s ease-in-out;
        &:hover {
          border-image-source: linear-gradient(
            to left,
            #ff9085 30%,
            #fb6fbb 99%
          );
          background-image: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        text-transform: uppercase;
        color: $light-color;
        margin-bottom: 0.5rem;
        font-size: $small-font-size;
        font-weight: $light-weight;
        line-height: 1.29;
        color: $light-color;
      }
    }
  }
}
