.profile-container {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.profile-overview {
    flex: 1.15;
    margin: 20px 20px;
    background-color: #212529;
    padding: 10px;
    border-radius: 5px;
}

.accounts {
    flex: 0.85;
    margin: 20px 20px;
    background-color: #212529;
    padding: 10px;
    border-radius: 5px;
    height: 617px;
}

@media (max-width: 820px) {
    .profile-container {
        flex-direction: column;
    }

    .profile-overview,
    .accounts {
        margin: 10px 0;
        width: 100%;
    }
}