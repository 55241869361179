.container-risk-warning {
    color: #EAECEF;
    font-size: 16px;
    margin-top: 50px;
}

.content-risk-warning {

    a {
        color: pink;
        text-decoration: underline;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        tr {
            /* height: fit-content; */
            border: 1px solid #474d57;

            th {
                text-align: center;
                border: 1px solid #474d57;
                padding: 10px 0px;
            }

            td {
                border: 1px solid #474d57;
                padding: 10px 0px;
            }

            td:first-child {
                text-align: center;
                width: 30%;
            }

            td:not(:first-child) {
                text-align: left;
                padding-left: 10px;
            }


        }

        tbody tr:hover {
            background-color: #b7bdc660;
            /* Light gray background color on hover */
        }
    }

    .table2 {
        border-collapse: collapse;
        width: 100%;

        thead {
            tr {
                border: 1px solid #474d57;

                th {
                    text-align: center;
                    border: 1px solid #474d57;
                    padding: 10px 0px;
                }
            }
        }

        .tbody2 {
            tr {
                border: 1px solid #474d57;

                td {
                    border: 1px solid #474d57;
                    padding: 10px 0px;
                    text-align: left;
                    padding-left: 10px;
                }

                td:first-child {
                    width: 60%;
                }
            }
        }

        tbody2 tr:hover {
            background-color: #b7bdc660;
            /* Light gray background color on hover */
        }
    }

}

.content-risk-warning>* {
    padding: 12px 0px;
}

.center-text {
    text-align: center;
}

.bold-text {
    font-weight: 700;
}