.list_stacking_me {
  text-align: center;
  margin: 0px 0px 0px 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.data-table-stacking {
  margin: 10px;
  border-collapse: collapse;
  width: 99%;
  margin-top: 10px;
  table-layout: fixed;

  tr {
    border-bottom: 1px solid #25292f;
    /* Đường line mảnh giữa mỗi hàng */
    height: 39px;
  }

  th {
    font-size: 12px;
    text-align: left;
    background-color: solid transparent;
    color: #808080;
    position: relative;
  }

  thead tr {
    border-bottom: 1px solid #25292f;
    /* Đường line đậm giữa hàng đầu tiên và thead */
  }

  thead tr th {
    padding: 10px 0px;
  }

  tbody {
    /* height: 390px; */
  }

  tbody tr td {
    color: #dbdee1;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #4CAF50;
  color: black;
}

.pagination button {
  width: 30px;
  border-radius: 5px;
}

.active {
  background-color: lightsteelblue;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  color: black;
}

@media (max-width: 600px) {
  .data-table-stacking thead {
    display: none;
    /* Ẩn phần tiêu đề khi màn hình nhỏ */
  }

  .data-table-stacking tbody tr {
    height: 140px;
    display: block;
  }

  .data-table-stacking tbody td {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 14px;
    text-align: left;
  }

  .data-table-stacking tbody td:before {
    content: attr(data-label);
    /* Thêm nhãn cột vào trước nội dung */
    font-weight: bold;
    color: #808080;
  }

  .data-table-stacking tbody tr:first-child {
    border-top: none;
    /* Ẩn đường viền cho ô cuối cùng */
  }
}