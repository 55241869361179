.edit-view {
    background: #1e1e1e;
    border-radius: 5px;
    padding: 20px;
    position: absolute;
    /* top: 100; */
    /* right: 100; */
    z-index: 1000;
    width: 300px;
  }
  
  .edit-field {
    /* margin-bottom: 15px; */
  }
  
  /* .edit-field label {
    display: block;
    margin-bottom: 5px;
    color: #888;
  } */
  
  /* .edit-field div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
/*   
  .edit-field input {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    background: #333;
    border: none;
    color: #fff;
    border-radius: 3px;
  } */
  
  .edit-field span {
    color: #888;
  }
  
  .edit-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .edit-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .edit-actions button:first-child {
    background: #555;
    color: #fff;
  }
  
  .edit-actions button:last-child {
    background: #f0b90b;
    color: #000;
  }
  