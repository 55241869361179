@import "../../../../css/styles/_variables.scss";

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.content_items {
  height: 240px;
}

.pagination_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.pagination_controls button {
  padding: 5px 10px;
  background-image: $gradient-color;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 20px;
}

.pagination_controls button:disabled {
  background-image: none;
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination_controls button:hover:not(:disabled) {
  background-image: $gradient-color;
}

.limit-controls {
  text-align: center;
  margin-bottom: 20px;
}

.limit-controls label {
  font-size: 16px;
}

.limit-controls select {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.item_balance {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}