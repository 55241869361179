@import "../../../css/styles/_variables.scss";
@import "../../../css/styles/_mixins.scss";
@import "../../../css/styles/_global.scss";
@import "../../../css/main.1b01af96.css";

.container_register {

    // display: flex;
    // background-color: #212529;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // border-radius: 5px;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    // width: 40%;
    // padding: 20px;
    // height: auto;
    box-shadow: 0px 0px 16px 4px rgba(10, 10, 10, 0.5);
    /* Tạo hiệu ứng bóng đổ */
    padding: 10px 20px;

    .form_register {
        label {
            display: block;
            margin-bottom: 10px;
        }

        input {
            width: 100%;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ccc;
            margin-bottom: 10px;
        }
    }
}

.form_register  >* {
    padding: 3px 0px // margin: 15px 0px;
}

.container_register .sub_register {

    padding-top: 10px;
    p {
        font-size: $small-font-size;
      }
    
      p:hover {
        background-image: $gradient-color;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
}

// }

.btn_grad {
    width: 100%;
    background-image: $gradient-color;
    padding: 15px 25px;
    margin: 5px 0px 10px 5px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: $secondary-color;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 600;

    &:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
    }
}

.otp_container {
   
    .otp_input {
        display: flex;
        justify-content: center; 
    }

    .remainResend {
        display: flex;
        justify-content: space-between;

        .remain {
            display: flex;
        }
        .resend {

            &:hover {
                background-image: $gradient-color;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                cursor: pointer;
              }
        }
    }
}


.otp_container  >* {
    padding: 10px 0px;
}

.checkbox_term_policy {
    display: flex;
    color: #fff;
    justify-content: flex-start;

    input[type="checkbox"] {
        width: 25px;
    }

    a:link, a:visited {
        color: pink;
        text-decoration: underline;
        cursor: auto;
      }
      
      a:link:active, a:visited:active {
        color: pink;
      }
}