@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";
@import "../../styles/_global.scss";
@import "../../main.1b01af96.css";

.popup_inner {
    display: flex;
    background-color: #212529;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width: 115%;
    min-width: 300px;

    .login_content {
        padding: 20px;
        p {
            text-align: center;
            color: #fff;
        }
    }
    .login_content > * {
        margin: 15px 0px;
    }

    h3 {
        color: #fff;
        font-size: 21.33px;
        font-weight: 700;
        line-height: 31px;
        text-align: center;

    }

    label {
        display: block;
        margin-bottom: 10px;
    }

    input {
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    button[type="submit"] button[type="button"] {
        background-color: #4CAF50;
        color: white;
        border: none;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;
    }

    button[type="submit"] {
        margin-top: 20px;
    }

    button[type="button"]:disabled {
        background-color: #bfbfbf;
        color: #ffffff;
        cursor: not-allowed;
    }

}

/* Media query để ẩn ảnh khi màn hình nhỏ */
@media (max-width: 1075px) {
    .logo_login {
        display: none;
    }

    .popup_inner {
        width: 90%; /* Điều chỉnh chiều rộng cho màn hình nhỏ hơn */
    }
}

@media (max-width: 480px) {
    .popup_inner {
        width: 120%; /* Tăng width hơn nữa cho màn hình rất nhỏ */
        min-width: 300px;
    }
}