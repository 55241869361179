.dashboard {
    color: white;
    width: 100%; 

    table {
        // font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      th {
        border: 1px solid white;
      }

      td {
        border: 1px solid white;
      }
      
      td, th {
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
        color: black;
      }

      .user {
        width: 40%;
      }

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        width: 100%;
      }
      
      .pagination a {
        color: white;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
      }
      
      .pagination a.active {
        background-color: #4CAF50;
        color: black;
      }

      .pagination button {
        width: 30px;
        border-radius: 5px;
      }

      .active {
        background-color: lightsteelblue;
        border-radius: 5px;
      }
      
      .pagination a:hover:not(.active) {background-color: #ddd; color: black;}

      .user-avatar {
        border-radius: 5px;
        width: 30px;
        height: 30px;
      }

      .dashboard-search {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-end;
      }

      .dashboard-search-input {
        border-radius: 5px;
        width: 30%;
        height: 90%;
        background-color: #222222;
        border: 0px;
        outline: none;
        color: white;
        font-size: 16px;
        font-weight: 400;
      }

      .dashboard-search-input:hover {
        border: 0.5px solid white;
      }

      .notification {
        border-radius: 5px;
        outline: none;
        border: 0px;
      }

      .td-textarea {
        width: 50px;
        text-align: center;
      }
}
