.sidebar {
    height: 50px;
    width: 250px;
    left: 0;
    padding-top: 20px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 10px;
    text-align: left;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: white;
    display: block;
  }
  
  .sidebar ul li a:hover {
    background-color: #575757;
  }
  