.overlay-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.2);
    /* z-index: 99999999; */
}

.content.disabled {
    pointer-events: none;
    filter: blur(5px);
    /* Làm mờ nội dung khi đang tải */
}

.hub {
    position: absolute;
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -50%);
}