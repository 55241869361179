@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";
@import "../../../styles/_global.scss";

.hero {
  position: relative;
  @include respond-to(tablet-p) {
    @include flexCenter;
  }

  .hero__video {
  }
  .hero__video__overlay {
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hero__left {
    @include respond-to(tablet-p) {
      width: 84% !important;
    }
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &::after {
      // content: url(../../../assets/images/backgrounds/Hero.svg);
      position: absolute;
      top: -20px;
      z-index: -1;
    }
    &::before {
      // content: url(../../../assets/images/backgrounds/Circle.svg);
      position: absolute;
      top: 100px;
      left: 25%;
      z-index: -1;
    }
    .hero__left__text {
      h1 {
        @include respond-to(tablet-p) {
          font-size: 44px !important;
        }
        color: $light-color;
        width: fit-content;
        font-size: $title-font-size;
        &:nth-child(1) {
          font-weight: $bold-weight;
        }
        &:nth-child(2),
        &:nth-child(3) {
          font-weight: $light-weight;
        }
      }
    }
    .hero__left__btns {
      width: fit-content;
      display: flex;
      gap: 25px;
      .hero__left__btns_items {
        background-color: transparent !important;
        color: $light-color;
        border: 2px solid $light-color;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .hero__left__bottomText {
      color: $light-color;
      font-size: $extra-large-font-size;
    }
    .hero__left__backedBy {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        outline: none;
        border-image-source: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        background-image: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        font-weight: $bold-weight;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @include respond-to(tablet-p) {
        img {
          width: 200px !important;
        }
      }
      .hero__left__backedBy__logos {
        @include respond-to(tablet-p) {
          flex-direction: column;
        }
        display: flex;
        gap: 2rem;
        width: 250px;
      }
    }
  }
}

.headerVideo {
  position: fixed;
  @include respond-to(tablet-p) {
    height: 150vh !important;
  }
}
