.container {
  /* width: 100%; */
  margin: 5px;
}

.wallet-container {
  background-image: url('./../../assets/images/icons/wallet/BackgroundWallet.png'); /* Đặt đường dẫn hình ảnh của bạn ở đây */
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  color: white; /* Màu chữ trắng để hợp với theme đen */
  padding: 20px;
  margin: auto; /* Căn giữa div */
  /* text-align: center; Căn giữa text */
}

.sub-title {
  display: flex;
  align-items: center;
}
.eye-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 10px;
}

.wallet-text {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 24px; /* Tùy chỉnh kích thước chữ */
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0px 20px;
}

.wallet-button {
  background: rgba(255, 255, 255, 0.4); /* Nền trong suốt với độ mờ */
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s;
}

.wallet-button:hover {
  background: #212529;
}

.button-content {
  display: flex;
  align-items: center;
  text-align: left;
}

.button-image {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.button-title {
  font-size: 16px;
}