@import "../../styles//variables";

.container {
  background-color: $card-bg-color;
  margin: 2rem 0rem;
  padding: 1rem;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 100%;
    }
    height: 452px;
    border-radius: 20px;
    background: $card-bg;
    .cardContent {
      width: 80%;
      display: flex;
      color: $light-color;
      height: 100%;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      .credit {
        color: #757575;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.36px;
      }
      h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.48px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        strong {
          font-size: 44px;
          font-weight: 700;
          line-height: 52px; /* 118.182% */
          letter-spacing: -2.2px;
        }
      }
    }
    button {
      width: 80%;
      background-image: linear-gradient(
        to right,
        #fb6fbb 0%,
        #ff9085 51%,
        #fa1191 100%
      );
      text-align: center;
      text-transform: uppercase;
      transition: 0.5s;
      background-size: 200% auto;
      color: #151517;
      border-radius: 16px;
      height: 70px;
      display: block;
      border: none;
      padding: 0rem 2rem;
      font-weight: 700;
      color: $light-color;
      cursor: pointer;
    }
  }
}
