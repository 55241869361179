.container_container_position_future {
  text-align: center;
  margin: 0px 0px 0px 5px;
}

.data-table-position-future {
  margin: auto;
  border-collapse: collapse;
  width: 99%;
  margin-top: 10px;
  table-layout: fixed;

  tr {
    border-bottom: 1px solid #25292f;
    /* Đường line mảnh giữa mỗi hàng */
  }

  th {
    font-size: 12px;
    text-align: left;
    background-color: solid transparent;
    color: #808080;
    position: relative;
  }

  thead tr {
    border-bottom: 1px solid #25292f;
    /* Đường line đậm giữa hàng đầu tiên và thead */
  }

  thead th:nth-child(1) {
    width: 15%;
    /* Thay đổi giá trị width tùy ý */
  }

  thead th:nth-child(2),
  th:nth-child(3) {
    width: 6%;
    /* Thay đổi giá trị width tùy ý */
  }

  thead th:nth-last-child(3) {
    width: 6%;
    /* text-align: center; */
  }

  thead th:nth-last-child(2) {
    width: 5%;
    text-align: center;
  }

  thead th:last-child {
    width: 8%;
    text-align: center;
  }

  tbody tr {
    height: 39px;
  }

  tbody tr:hover {
    background-color: #A3B0BD50;
  }

  tbody td:last-child,
  td:nth-last-child(2) {
    text-align: center;
  }

  tbody td:last-child button {
    &:hover {
      background-color: #fd7fa2;
    }
  }

  tbody tr td {
    color: #dbdee1;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;

    .price:hover {
      width: 13.5px;
      height: 13.5px;
    }
  }
}

.data-table-position-future td button {
  background-color: transparent;
  color: white;
  border: none;
  /* padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.data-table-position-future .cancel_button {
  color: white;
  border: none;
  /* padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #2b3139;
  padding: 5px;
}

.data-table-future td button img:hover {
  /* background-color: #d32f2f; */
  width: 20px;
  height: 20px;
}

.dropdown {
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  justify-content: center;
  color: #808080;
  /* padding: 8px; */
  font-size: 14px;
  border: none;
  cursor: pointer;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1e2328;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  border-width: 1px solid #282e34;

  .selected-dropdown {
    color: #2954ff;
  }
}

.dropdown-content span {
  color: #e9ecef;
  padding: 6px 16px;
  /* text-decoration: none; */
  display: block;
}

.dropdown-content span:hover {
  background-color: #8a9098
}

.dropdown:hover .dropdown-content {
  display: block;
}

.edit-future-modal {
  position: absolute;
  height: 400px;
  max-width: 500px;
  margin: auto;
}

@media (max-width: 850px) {
  .edit-future-modal {
      max-width: 400px;
  }
}

@media (max-width: 400px) {
  .edit-future-modal {
      max-width: 350px;
  }
}

@media (max-width: 770px) {
  .data-table-position-future thead {
    display: none;
    /* Ẩn phần tiêu đề khi màn hình nhỏ */
  }

  .data-table-position-future tbody tr {
    height: 330px;
    display: block;
  }

  .data-table-position-future tbody td {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 14px;
    text-align: left;
  }

  .data-table-position-future tbody td:before {
    content: attr(data-label);
    /* Thêm nhãn cột vào trước nội dung */
    font-weight: bold;
    color: #808080;
  }

  .data-table-position-future tbody tr:first-child {
    border-top: none;
    /* Ẩn đường viền cho ô cuối cùng */
  }

  .data-table-position-future tbody td:last-child {
    margin-top: -7px;
  }
}