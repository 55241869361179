@import './../../../css/styles/variables';
@import "./../../../css/styles/mixins";
@import "./../../../css/styles/_global.scss";
@import "./../../../css/main.1b01af96.css";


.forgot_password {
    box-shadow: 0px 0px 16px 4px rgba(10, 10,10, 0.5); /* Tạo hiệu ứng bóng đổ */
  padding: 20px
}
.forgot_password > * {
  margin: 10px 5px;
}
.forgot_password p {
    font-size: 25px;
    text-align: left !important;
  }
.forgot_password .backButton{
    display: block; /* Đảm bảo hình ảnh được hiển thị dưới dạng khối để căn lề */
    margin-top: 20px;
    width: 50px;
    height: 30px;
    cursor: pointer;
   
}

.forgot_password .backPrevious {
   

    p {
      font-size: $small-font-size;
    }
  
    p:hover {
      background-image: $gradient-color;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
}


.btn_grad {
  width: 100%;
  background-image: $gradient-color;
  padding: 15px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: $secondary-color;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 600;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
}
