@import "../../../styles/variables";
@import "../../../styles/mixins";
.ecosystem {
  display: flex;
  flex-direction: column;
  gap: 56px;
  @include respond-to(tablet-p) {
    flex-direction: column;
    width: 82%;
    padding-left: 1rem;
  }
  &::after {
    // content: url("../../../../js/assets/images/DaoPeople.svg");
    position: absolute;
    z-index: -1;
    left: 42%;
    margin-top: -4rem;
    @include respond-to(tablet-p) {
      display: none;
    }
  }
  .newslatter_wrapper {
    display: flex;
    margin: 3rem 0rem;
    justify-content: center;
    width: 100%;
    align-items: center;
    @include respond-to(tablet-p) {
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0rem;
    }
    .newslatter_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 90%;
      @include respond-to(tablet-p) {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        margin-left: 3rem;
        text-align: center;
        input {
          width: 100%;
        }
      }
      span {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .newslatter_input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #fb6fbb;
      background: #232325;
      color: #fff;
      padding: 0 1rem;
      font-size: 1.2rem;
      width: 50%;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #fff;
        opacity: 0.5;
      }
    }
  }
  .ecosystem__top {
    display: flex;
    justify-content: space-between;
    .ecosystem__top__left {
      gap: 23px;
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 37.9px;
        font-weight: $bold-weight;
        color: $light-color;
        text-transform: uppercase;
      }
      p {
        color: $card-title-color;
      }
    }
    .ecosystem__top__right {
      button {
        position: relative;
        cursor: pointer;
        right: -15px;
        width: 50px;
        height: 50px;
        padding: 7px;
        @include flexCenter();
        font-size: 2rem;
        color: $light-color;
        transition: all 0.3s ease-in-out;
        border-radius: 50%;
        font-weight: $light-weight;
        border-image-source: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        border-image-slice: 1;
        background-image: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        &:hover {
          scale: 1.1;
        }
      }
      display: flex;
      align-items: start;
    }
  }
  .ecosystem__bottom {
    display: flex;
    justify-content: space-between;
    gap: 29px;
    @include respond-to(tablet-p) {
      flex-direction: column;
    }
    .ecosystem__bottom__card__container {
      width: 100%;
      display: flex;
      align-items: start;
      flex-direction: column;
      gap: 1rem;
    }
    .ecosystem__bottom__card {
      position: relative;
      min-height: 156px;
      flex-grow: 0;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 25px 40px;
      border-radius: 20px;
      background-color: $card-bg-color;
      justify-content: space-between;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      border: 1px solid transparent;

      h3 {
        font-size: extra-large-font-size + 2px;
        font-weight: $bold-weight;
        color: $light-color;
      }
      p {
        color: $card-desc-color;
      }
      span {
        width: 50px;
        height: 50px;
        color: $light-color;
        transition: all 0.3s ease-in-out;
        border-radius: 0.5rem;
        color: $card-bg-color;
        font-size: 21px;
        @include flexCenter();
        border-image-source: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        border-image-slice: 1;
        background-image: linear-gradient(to left, #ff9085 30%, #fb6fbb 99%);
        position: absolute;
        right: -17px;
        top: -27px;
      }
    }
    .ecosystem__bottom__card__button {
      font-weight: $bold-weight;
      background: none;
      border: none;
      font-size: $large-font-size;
      color: $light-color;
      @include flexCenter();
      gap: 0.6rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
