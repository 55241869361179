@import "../../styles/_mixins.scss";
@import "../../styles/_variables.scss";

.notification {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #00410b;
  right: 30px;
  border-radius: 1rem;
  border: 2px solid #2aff00;
  padding-right: 6rem !important;
  padding: 1rem;
  min-height: 110px;
  font-size: 1.2rem;
  min-width: 300px;
  color: #2aff00;
  display: flex;
  align-items: center;
  margin-top: 80px;
}

.danger {
  background-color: #4a0000;
  border: 2px solid #ff0000;
  color: #ff0000;
}

.notification::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../../js/assets/images/Robot.svg");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right 0px top 0px;
  filter: grayscale(100%) opacity(0.9);
  z-index: 99999;
  z-index: -1;
}
