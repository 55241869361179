.otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 10px;
  }
  
  .otp-input {
    width: 100%;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
  }
  