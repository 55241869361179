.currency {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        text-align: left;
        color: #e9e9e9;
        font-weight: 100;
        width: 70%;
        padding-bottom: 2px;
        font-size: 12px;
    }

    input {
        height: 100%;
        width: 70%;
        border: none;
        outline: none;
        text-align: left;
        font-size: 16px;
        background-color: #222222;
        color: white;
        flex-direction: column;
    }

    input::placeholder {
        font-size: 16px
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
}

.dropdown-input:hover {
    border: 0.5px solid white;
}

.dropdown-input:focus-within {
    border: 0.5px solid white;
}

.dropdown-input {
    display: flex;
    align-items: flex-start;
    height: 52px;
    width: 100%;
    padding: 7px 0px 8px 9px;
    border-radius: 5px;
    background-color: #222222;
    margin-bottom: 30px;

    select {
        border-radius: 5px;
        margin-top: 20px;
        width: 95px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        border: none;
        outline: none;
        background-color: #222222;
        color: #a6acb5;
    }
}
