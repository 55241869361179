.admin {
    width: 100%;
}

.admin-sidebar {
    width: 20%;
    height: 200px;
    float: left;
}

.admin-dashboard {
    margin-left: 20%;
    width: 80%;
    height: 400px;
    float: right;
    margin-top: -170px;
}
