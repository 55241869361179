.container {
  width: '100%';

  p {
    font-size: 12px;
    color: #fff;
  }

  .availablePrice {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  
    p {
        color: white;
        font-size: 14px;
        font-weight: 600;
    }
  }

  .profit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  
    p {
        color: #23CE31;
        font-size: 16px;
        font-weight: 600;
    }
  }

  .nonStacking {
    margin-top: 20px;
    p {
      font-size: medium;
      color: #fff;
    }
  }
}

.container>* {
  margin: 10px 0px;
}

.inputView {

}

.inputView>* {
  margin: 10px 0px;
}

.buttonStart {
  margin-top: 40px;
}